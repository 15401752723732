import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { /*debounce, */union, difference } from 'lodash';
import classnames from 'classnames';
import {
  Paper, TablePagination,
  ListItem, ListItemText, Divider, MenuItem, Typography, Link,
} from '@material-ui/core';

import { DEFAULT_PAGE_SIZE, ACCEPTED_PAGE_SIZES } from 'constants/tables';
import { MySQL } from 'service/database';
import {
  updateURLWithFilters, parseNull, getErrorMessage, somePropsChanged, scrollToTop,
} from 'service/utility';
import { toBe } from 'service/utility/numbers';

import { HamburgerButton, HamburgerMenu } from '../../hamburger';
import { LocationFilter } from '../../listFilters';
import { SearchBar } from '../../searches';
import { Spinner, StatusOverlay } from '../../statusIndicators';
import DataTable from '../DataTable';
import FieldChip from './FieldChip';


const getVisibleSelectedItemIds = (state) => {
  const dataIds = state.data.map((e) => e.id);

  return state.selectedItemIds.filter((id) => dataIds.includes(id));
};


class DynamicDataList extends React.Component {
  constructor(props) {
    super(props);

    this._index = 0;
    const propsFilters = this.props.filters ?? [
      <LocationFilter
        key={0}
        currentLocation={this.props.currentLocation}
      />,
    ];
    this.filters = this.constructor.getFilters(propsFilters, this.props.useURLParams);
    this.freshState = {
      loading: true,
      data: [],
      pageCount: 0,
      rowCount: 0,
      searchString: this.getParamOrProp('searchString'),
      pageSize: Number(this.getParamOrProp('pageSize')),
      page: Number(this.getParamOrProp('page')),
      order: this.getParamOrProp('order'),
      orderBy: parseNull(this.getParamOrProp('orderBy')),
      pageDirection: true, // true if moving fwd (hitting next), false if moving backwards (hitting prev)
      maxPageEncountered: 0,
      maxRowsEncountered: 0,
      selectedItemIds: this.props.selectedItemIds,
      selectedItems: [],
      hamburgerAnchorEl: null,
    };

    this.state = {
      ...this.freshState,
      filters: {},
      filtersInitialized: {},
      filterWasJustInitialized: {},
    };
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.childRef) {
      this.props.childRef(this);
    }

    // this.getData = debounce(this.getData_, 500);

    if (!this.filters.length) {
      this.getData();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      Object.keys(prevState.filtersInitialized).length !== this.filters.length &&
      Object.keys(this.state.filtersInitialized).length === this.filters.length
    ) {
      this.getData();
    }

    if (this.props.onSelectedItemsChange && somePropsChanged(prevState, this.state, ['selectedItemIds'])) {
      this.props.onSelectedItemsChange(this.state.selectedItemIds, this.state.selectedItems);
    }
  }

  componentWillUnmount() {
    if (this.props.childRef) {
      this.props.childRef(undefined);
    }

    this._isMounted = false;
  }


  getParamOrProp = (p) => {
    if (!this.props.useURLParams) return this.props[p];

    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.has(p) ? urlParams.get(p) : this.props[p];
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  forceReload = () => {
    this.setState(this.freshState, this.getData);
  };

  allFiltersInitialized = () => (
    Object.keys(this.state.filtersInitialized).length === this.filters.length
  );

  updateSelectedItemsFromData = () => {
    const { data } = this.state;
    const { selectedItemIds } = this.props;

    // find all items in data that match selectedItemIds maybe?
    const selectedItems = data.filter((item) => selectedItemIds.includes(item.id));

    if (selectedItems.length > 0) {
      this.setState({ selectedItems });
    }
  };

  afterDataUpdate = () => {
    scrollToTop();
    this.updateSelectedItemsFromData();
  };

  // getData_ = () => {
  getData = () => {
    if (!this.allFiltersInitialized()) return;

    this._index += 1;
    const currentIndex = this._index;

    this.setState({ updating: true });

    const { getAPI, getParams, queryParams, database, postProcess } = this.props;
    const {
      searchString, page, pageSize, pageDirection, order, orderBy, data,
      maxPageEncountered, maxRowsEncountered, filters,
    } = this.state;

    const queryString = database.getQueryString(
      searchString, page, pageSize, pageDirection, orderBy, order, filters, data,
    );
    const fullQueryString = queryParams ? `${queryParams}&${queryString}` : queryString;

    console.log('DynamicDataList: querying data; fullQueryString: ', fullQueryString);

    getAPI(...getParams, fullQueryString).then(
      (response) => {
        if (currentIndex !== this._index) return;

        const newData = database.getData(response);

        console.log('DynamicDataList: retrieved the following data:');
        console.log(newData);

        const finalData = postProcess ? newData.map(postProcess) : newData;

        if (postProcess) {
          console.log('DynamicDataList: final post-processed data:');
          console.log(finalData);
        }

        const newState = {
          data: finalData,
          loading: false,
          updating: false,
          maxPageEncountered,
          maxRowsEncountered,
        };

        if (newData.length > 0 && page > maxPageEncountered) {
          newState.maxPageEncountered = page;
          newState.maxRowsEncountered = maxRowsEncountered + newData.length;
        }

        newState.pageCount = database.getPageCount(response, newState.maxPageEncountered);
        newState.rowCount = database.getRowCount(response, newState.maxRowsEncountered);

        if (this._isMounted) {
          this.setState(newState, this.afterDataUpdate);
        }
      }
    ).catch((error) => {
      const errorMessage = getErrorMessage(error);

      console.error('DynamicDataList: error while querying data:', errorMessage);

      toast.error(errorMessage);

      if (this._isMounted) {
        this.setState({
          loading: false,
          updating: false,
        });
      }
    });
  };

  getDataAndUpdateURL = () => {
    const { history, useURLParams } = this.props;
    const { filters, searchString, pageSize, page, order, orderBy } = this.state;

    if (useURLParams) {
      const filterMap = {
        ...filters,
        searchString,
        pageSize,
        page,
        order,
        orderBy,
      };

      updateURLWithFilters(filterMap, history);
    }

    this.getData();
  };

  getPagination = () => {
    const { rowCount, page, pageSize } = this.state;

    return (
      <TablePagination
        component="div"
        count={rowCount}
        rowsPerPage={pageSize}
        page={page - 1}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        onPageChange={this.handlePageChange}
        onRowsPerPageChange={this.handlePageSizeChange}
        rowsPerPageOptions={ACCEPTED_PAGE_SIZES}
        labelRowsPerPage="Items per page:"
        classes={{ root: 'pagination' }}
      />
    );
  };

  getSearchableFields = () => (
    this.props.fieldsConfig?.filter((field) => (
      !field.notSortable && !field.notSearchable
    )) ?? []
  );

  getPlaceholder = () => {
    const searchableFields = this.getSearchableFields();
    const fieldsLen = searchableFields.length;

    return !fieldsLen
      ? 'Search'
      : `Search by ${fieldsLen === 1
        ? `${searchableFields[0].label}.`
        : searchableFields.map(
          (field, pos) => (
            pos === (fieldsLen - 2)
              ? `${field.label} `
              : pos !== (fieldsLen - 1)
                ? `${field.label}, `
                : `or ${field.label}.`
          )
        ).join('')}`;
  };

  getIsSearchingByBirthdate = () => {
    const searchableFields = this.getSearchableFields();

    return searchableFields.some((field) => field.sortString === 'birthdate');
  };

  handlePageChange = (e, page) => {
    this.setState(
      (prevState) => ({
        page: page + 1,
        pageDirection: (page + 1) > prevState.page,
      }),
      this.getDataAndUpdateURL,
    );
  };

  handlePageSizeChange = (event) => {
    this.setState(
      {
        pageSize: event.target.value,
      },
      this.getDataAndUpdateURL,
    );
  };

  handleSortBy = (newOrderBy) => {
    this.setState((prevState) => {
      const { orderBy, order } = prevState;
      const newOrder = orderBy === newOrderBy && order === 'ASC' ? 'DESC' : 'ASC';

      return {
        order: newOrder,
        orderBy: newOrderBy,
      };
    }, this.getDataAndUpdateURL);
  };

  handleSearchStringChange = (searchString) => {
    this.setState(
      {
        searchString,
        // page: 1,
      },
      // this.getDataAndUpdateURL,
    );
  };

  handleSearch = (searchString) => {
    this.setState(
      {
        searchString,
        page: 1,
      },
      this.getDataAndUpdateURL,
    );
  };

  // handleSearchEnter = () => {
  //   if (this.state.data.length === 1 && this.props.onItemClick) {
  //     this.props.onItemClick(this.state.data[0]);
  //   }
  // };

  handleFilterValueChange = (filterKey, update, callback) => {
    this.setState(
      (prevState) => {
        const newState = {
          filters: {
            ...prevState.filters,
            ...update,
          },
          filtersInitialized: {
            ...prevState.filtersInitialized,
            [filterKey]: true,
          },
          filterWasJustInitialized: {
            ...prevState.filterWasJustInitialized,
            [filterKey]: prevState.filtersInitialized[filterKey] !== true,
          },
        };

        if (prevState.filtersInitialized[filterKey]) {
          newState.page = 1;
        }

        return newState;
      },
      () => {
        if (callback) {
          callback(update);
        }

        if (!this.state.filterWasJustInitialized[filterKey]) {
          this.getDataAndUpdateURL();
        }
      }
    );
  };

  handleHamburgerClick = (event) => {
    this.setState({
      hamburgerAnchorEl: event.currentTarget,
    });
  };

  closeHamburgerMenu = () => {
    this.setState({
      hamburgerAnchorEl: null,
    });
  };

  handleSelectAll = () => {
    this.setState((prevState) => {
      const visibleSelectedItemIds = getVisibleSelectedItemIds(prevState);
      let selectedItemIds;
      let selectedItems;

      if (visibleSelectedItemIds.length === 0) {
        const visibleItemIds = prevState.data.map((e) => e.id);
        const newSelectedItemIds = visibleItemIds.filter((id) => !prevState.selectedItemIds.includes(id));
        selectedItemIds = union(prevState.selectedItemIds, newSelectedItemIds);
        const newSelectedItems = prevState.data.filter((item) => newSelectedItemIds.includes(item.id));
        selectedItems = union(prevState.selectedItems, newSelectedItems);
      } else {
        selectedItemIds = difference(prevState.selectedItemIds, visibleSelectedItemIds);
        selectedItems = prevState.selectedItems.filter((item) => !visibleSelectedItemIds.includes(item.id));
      }

      return {
        selectedItemIds,
        selectedItems,
      };
    });
  };

  handleSelectAll2 = () => {
    this.setState((prevState) => {
      const visibleSelectedItemIds = getVisibleSelectedItemIds(prevState);
      let selectedItemIds;
      let selectedItems;

      if (visibleSelectedItemIds.length === prevState.pageSize) {
        selectedItemIds = difference(prevState.selectedItemIds, visibleSelectedItemIds);
        selectedItems = prevState.selectedItems.filter((item) => !visibleSelectedItemIds.includes(item.id));
      } else {
        const visibleItemIds = prevState.data.map((e) => e.id);
        const newSelectedItemIds = visibleItemIds.filter((id) => !prevState.selectedItemIds.includes(id));
        selectedItemIds = union(prevState.selectedItemIds, newSelectedItemIds);
        const newSelectedItems = prevState.data.filter((item) => newSelectedItemIds.includes(item.id));
        selectedItems = union(prevState.selectedItems, newSelectedItems);
      }

      return {
        selectedItemIds,
        selectedItems,
      };
    });
  };

  handleItemToggle = (itemId) => {
    this.setState((prevState) => {
      const newSelectedItemIds = [...prevState.selectedItemIds];
      const currentIndex = newSelectedItemIds.indexOf(itemId);
      const selectedItem = prevState.data.find((dataItem) => dataItem.id === itemId);
      const newSelectedItems = [...(prevState.selectedItems || [])];

      if (currentIndex === -1) {
        newSelectedItemIds.push(itemId);
        if (selectedItem) {
          newSelectedItems.push(selectedItem);
        }
      } else {
        newSelectedItemIds.splice(currentIndex, 1);
        const itemIndex = newSelectedItems.findIndex((item) => item.id === itemId);
        if (itemIndex !== -1) {
          newSelectedItems.splice(itemIndex, 1);
        }
      }

      return {
        selectedItemIds: newSelectedItemIds,
        selectedItems: newSelectedItems,
      };
    });
  };


  render() {
    const {
      fieldsConfig, paginationPosition, onItemClick, newButton,
      leftActions, rightActions, massActions, overlayNoItemsMessage,
      searchable, expansionPanel, rowIsExpandable, accordion,
      size, className, noItemsMessage, selectable,
    } = this.props;
    const {
      data, order, orderBy, loading, updating, rowCount, searchString, selectedItemIds, pageSize,
      hamburgerAnchorEl,
    } = this.state;

    const Item = this.props.item;
    const isSearchingByBirthdate = this.getIsSearchingByBirthdate();
    const searchNote = isSearchingByBirthdate ? (
      'Note: When searching by birthdate, please use the following format to get accurate results: YYYY-MM-DD'
    ) : null;

    const selectedItemCount = selectedItemIds.length;
    const visibleSelectedItemIds = getVisibleSelectedItemIds(this.state);
    const visibleSelectedItemCount = visibleSelectedItemIds.length;

    const showLeftSection = leftActions.length > 0;
    const showCenterSection = searchable;
    const showRightSection = this.filters.length > 0 || newButton || rightActions.length > 0;
    const showHeaderRow = showLeftSection || showCenterSection || showRightSection;
    const showMassActionRow = selectedItemCount > 0;
    const showFilterRow = Boolean(Item && fieldsConfig);
    const showDataTableHeader = showHeaderRow || showMassActionRow || showFilterRow;

    return (
      <Paper className={classnames('ds-data-table', { [className]: Boolean(className) })}>
        {(this.props.updating || updating) && !loading && (
          <StatusOverlay fixed>
            <Spinner size={60} />
          </StatusOverlay>
        )}

        {showDataTableHeader && (
          <div className="ds-data-table-header">
            {showHeaderRow && (
              <div className="header-row">
                {showLeftSection && (
                  <div className="header-row-section left-section">
                    {leftActions.map((leftAction, idx) => (
                      <div
                        key={`_DDLA_${idx}`}
                        className="header-row-item"
                      >
                        {leftAction}
                      </div>
                    ))}
                  </div>
                )}
                {showCenterSection && (
                  <div className={classnames('header-row-section center-section', { 'pb-3': Boolean(searchNote) })}>
                    <div className="position-relative">
                      <SearchBar
                        placeholder={this.getPlaceholder()}
                        onQueryChange={this.handleSearchStringChange}
                        onSearch={this.handleSearch}
                        query={searchString}
                        autoFocus
                      // onEnter={this.handleSearchEnter}
                      />
                      {Boolean(searchNote) && (
                        <Typography
                          variant="body2"
                          component="span"
                          className="font-italic text-xsmall position-absolute mt-1 ml-2"
                        >
                          {searchNote}
                        </Typography>
                      )}
                    </div>
                  </div>
                )}
                {showRightSection && (
                  <div className="header-row-section right-section">
                    {this.filters.map((filter, idx) => {
                      const key = `_DDLF_${idx}`;

                      return (
                        <div
                          key={key}
                          className="header-row-item"
                        >
                          {React.cloneElement(
                            filter,
                            {
                              onValueChange: this.handleFilterValueChange,
                            },
                          )}
                        </div>
                      );
                    })}
                    {newButton && (
                      <div className="header-row-item">
                        {newButton}
                      </div>
                    )}
                    {rightActions.map((rightAction, idx) => (
                      <div
                        key={`_DDRA_${idx}`}
                        className="header-row-item"
                      >
                        {rightAction}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {showMassActionRow && (
              <div className="mass-action-row">
                <div className="center-section cml-1">
                  <Typography
                    variant="body2"
                    display="inline"
                  >
                    {`${selectedItemCount} total items ${toBe(selectedItemCount)} selected.`}
                  </Typography>
                  {visibleSelectedItemCount > 0 && (
                    <Typography
                      variant="body2"
                      display="inline"
                    >
                      {visibleSelectedItemCount === pageSize ? (
                        `All ${visibleSelectedItemCount} items on this page ${toBe(visibleSelectedItemCount)} selected.`
                      ) : (
                        `${visibleSelectedItemCount} items on this page ${toBe(visibleSelectedItemCount)} selected.`
                      )}
                    </Typography>
                  )}
                  <Link
                    component="button"
                    variant="body2"
                    className="font-weight-thick"
                    style={{ verticalAlign: 'unset' }}
                    onClick={this.handleSelectAll2}
                  >
                    {visibleSelectedItemCount === pageSize ? (
                      'Deselect all on this page.'
                    ) : (
                      'Select all on this page.'
                    )}
                  </Link>
                </div>
                {massActions.length > 0 && (
                  <HamburgerButton
                    onClick={this.handleHamburgerClick}
                    isActive={Boolean(hamburgerAnchorEl)}
                  />
                )}
                <HamburgerMenu
                  anchorEl={hamburgerAnchorEl}
                  open={Boolean(hamburgerAnchorEl)}
                  onClose={this.closeHamburgerMenu}
                >
                  {massActions.map((massAction, idx) => (
                    <MenuItem
                      key={`_MA_${idx}`}
                      onClick={() => { this.closeHamburgerMenu(); massAction.action(selectedItemIds); }}
                    >
                      {massAction.label}
                    </MenuItem>
                  ))}
                </HamburgerMenu>
              </div>
            )}

            {showFilterRow && (
              <div className="filter-row">
                <div className="order-chips">
                  {fieldsConfig.map((field) => (
                    <FieldChip
                      key={`OC_${field.sortString.replace(/,/g, '')}`}
                      field={field}
                      order={orderBy === field.sortString ? order : null}
                      onSortBy={this.handleSortBy}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}

        {paginationPosition === 'top' && (
          <div className="pagination-wrap">
            {this.getPagination()}
            <Divider />
          </div>
        )}

        <div className="ds-data-table-content">
          {loading ? (
            <Spinner size={60} />
          ) : rowCount === 0 ? (
            <ListItem
              component="div"
              className={classnames('no-items-message', { overlay: overlayNoItemsMessage })}
            >
              <ListItemText primary={noItemsMessage} />
            </ListItem>
          ) : (
            Item ? data.map(
              (item, idx) => React.cloneElement(
                Item,
                {
                  key: `_DDL_${idx}`,
                  value: item,
                }
              )
            ) : (
              <DataTable
                size={size}
                data={data}
                fieldsConfig={fieldsConfig}
                onItemClick={onItemClick}
                order={order}
                orderBy={orderBy}
                onSortBy={this.handleSortBy}
                expansionPanel={expansionPanel}
                rowIsExpandable={rowIsExpandable}
                accordion={accordion}
                selectable={selectable || massActions.length > 0}
                selectedItemIds={visibleSelectedItemIds}
                pageSize={pageSize}
                onSelectAll={this.handleSelectAll}
                onItemToggle={this.handleItemToggle}
              />
            )
          )}
        </div>

        {paginationPosition === 'bottom' && (
          <div className="pagination-wrap">
            <Divider />
            {this.getPagination()}
          </div>
        )}
      </Paper>
    );
  }
}

/* eslint-disable react/no-unused-prop-types */
DynamicDataList.propTypes = {
  accordion: PropTypes.bool,
  childRef: PropTypes.func,
  className: PropTypes.string,
  currentLocation: PropTypes.object.isRequired,
  database: PropTypes.object,
  expansionPanel: PropTypes.func,
  fieldsConfig: PropTypes.array,
  filters: PropTypes.array,
  getAPI: PropTypes.func.isRequired,
  getParams: PropTypes.array,
  history: PropTypes.object.isRequired,
  item: PropTypes.element,
  leftActions: PropTypes.array,
  massActions: PropTypes.array,
  newButton: PropTypes.element,
  noItemsMessage: PropTypes.string,
  onItemClick: PropTypes.func,
  onSelectedItemsChange: PropTypes.func,
  order: PropTypes.string, // 'ASC' or 'DESC'
  orderBy: PropTypes.string,
  overlayNoItemsMessage: PropTypes.bool,
  page: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  pageSize: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  paginationPosition: PropTypes.string, // 'top' or 'bottom' || 'both'
  postProcess: PropTypes.func,
  queryParams: PropTypes.string,
  rightActions: PropTypes.array,
  rowIsExpandable: PropTypes.func,
  searchable: PropTypes.bool,
  searchString: PropTypes.string,
  selectable: PropTypes.bool,
  selectedItemIds: PropTypes.array,
  size: PropTypes.string,
  updating: PropTypes.bool,
  useURLParams: PropTypes.bool,
};
/* eslint-enable react/no-unused-prop-types */

DynamicDataList.defaultProps = {
  accordion: false,
  database: MySQL,
  getParams: [],
  leftActions: [],
  massActions: [],
  noItemsMessage: 'No Results Found',
  order: 'ASC',
  orderBy: null,
  overlayNoItemsMessage: false,
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  paginationPosition: 'bottom',
  queryParams: '',
  rightActions: [],
  searchable: true,
  searchString: '',
  selectable: false,
  selectedItemIds: [],
  size: 'medium',
  useURLParams: true,
};

DynamicDataList.getFilters = (filterComponents, useURLParams) => {
  const urlParams = new URLSearchParams(window.location.search);

  return filterComponents.map((e) => {
    const eProps = {};
    const filterNames = (
      typeof e.type.ownFilters === 'function'
        ? e.type.ownFilters(e.props)
        : e.type.ownFilters
    );

    filterNames.forEach((filterName) => {
      if (useURLParams && urlParams.has(filterName)) {
        eProps[filterName] = urlParams.get(filterName);
      }
    });

    return React.cloneElement(e, eProps);
  });
};


export default withRouter(DynamicDataList);
