export const ATTENDEE_STATUS_MAP = {
  attended: 'Attended',
  cancelled: 'Cancelled',
  reserved: 'Reserved',
};

export const ATTENDEE_STATUS_PICKLIST_VALUES = Object.keys(ATTENDEE_STATUS_MAP).map((value) => ({
  name: ATTENDEE_STATUS_MAP[value],
  value,
}));
