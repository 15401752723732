import { createReducer } from 'service/utility/redux';

import { SET_CHECKIN_SETTINGS, SET_GENERAL_SETTINGS, SET_SETTINGS_LOADED, SET_DEFAULT_SETTINGS } from './actions';


const initState = {
  settingsLoaded: false,
  checkinSettings: {},
  generalSettings: {},
  defaultlSettings: {},
};

const settingsReducer = createReducer(initState, {
  [SET_CHECKIN_SETTINGS]: (state, { settings }) => ({
    ...state,
    checkinSettings: settings,
  }),
  [SET_GENERAL_SETTINGS]: (state, { settings }) => ({
    ...state,
    generalSettings: settings,
  }),
  [SET_DEFAULT_SETTINGS]: (state, { settings }) => ({
    ...state,
    defaultSettings: settings,
  }),
  [SET_SETTINGS_LOADED]: (state) => ({
    ...state,
    settingsLoaded: true,
  }),
});


export default settingsReducer;
