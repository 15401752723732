import React from 'react';
import { PropTypes } from 'prop-types';

import { ATTENDEE_STATUS_PICKLIST_VALUES } from 'constants/attendee';
import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const STATUS_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  ...ATTENDEE_STATUS_PICKLIST_VALUES.map((attendeeStatus) => ({
    label: attendeeStatus.name,
    value: attendeeStatus.value,
  })),
];


class AttendeeStatusFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      attendeeStatus: (
        this.props.hasOwnProperty('status') && this.props.status !== 'null'
          ? this.props.status
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['attendeeStatus'])) {
      this.handleValueChange();
    }
  }

  handleStatusChange = (update) => {
    if (!update.hasOwnProperty('value')) return;

    this.setState({
      attendeeStatus: update.value,
    });
  };

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  getOwnFilterMap = () => ({
    status: this.state.attendeeStatus === 0 ? null : this.state.attendeeStatus,
  });

  render() {
    const { attendeeStatus } = this.state;

    return (
      <PicklistField
        label="Status(es)"
        className="attendee-status-filter"
        value={attendeeStatus}
        onChange={this.handleStatusChange}
        picklistValues={STATUS_PICKLIST_VALUES}
      />
    );
  }
}

AttendeeStatusFilter.propTypes = {
  onValueChange: PropTypes.func,
  status: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

AttendeeStatusFilter.ownName = 'AttendeeStatusFilter';
AttendeeStatusFilter.ownFilters = ['status'];


export default AttendeeStatusFilter;
