import { DateTime } from 'luxon';

import * as i18nService from '../i18n';
import { dashJoin } from '.';
import { leftPadZero, capital } from './stringFormatters';


export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export const EMPTY_ISO8601_DATE = '0000-00-00';
export const EMPTY_ISO8601_TIME = '00:00';


export const getLuxon = (iso8601String, timeZone, locale) => {
  const dt1 = iso8601String ? DateTime.fromISO(iso8601String) : DateTime.local();
  const dt2 = timeZone ? dt1.setZone(timeZone) : dt1;
  const dt3 = locale ? dt2.setLocale(locale) : dt2;

  return dt3;
};


// returns a mask for the Short Date format, for example ##/##/####
export const getShortDateFormat = () => (
  DateTime.fromISO('1999-12-31').toLocaleString(DateTime.DATE_SHORT).replace(/\d/g, '#')
);

// returns an empty Short Date where digits are replaced with hyphens, for example --/--/----
export const getShortDateEmpty = () => (
  DateTime.fromISO('1999-12-31').toLocaleString(DateTime.DATE_SHORT).replace(/\d/g, '-')
);

// returns a placeholder for the Short Date format, for example MM/DD/YYYY
export const getShortDatePlaceholder = () => (
  DateTime.fromISO('1999-12-31')
    .toLocaleString(DateTime.DATE_SHORT)
    .replace(/1999/, 'YYYY')
    .replace(/12/, 'MM')
    .replace(/31/, 'DD')
);

// returns a placeholder for an interval of two Short Dates, for example MM/DD/YYYY – MM/DD/YYYY
export const getShortDateIntervalPlaceholder = () => (
  dashJoin([getShortDatePlaceholder(), getShortDatePlaceholder()])
);

// returns a special format for the Short Date format, to be used with react-datepicker,
// for example MM/dd/yyyy
export const getShortDateDatePickerFormat = () => (
  DateTime.fromISO('1999-12-31')
    .toLocaleString(DateTime.DATE_SHORT)
    .replace(/1999/, 'yyyy')
    .replace(/12/, 'MM')
    .replace(/31/, 'dd')
);

// returns a special format for the Short Date format, to be used with react-datepicker,
// for example MM/dd/yyyy
export const getSimpleTimeDatePickerFormat = () => (
  DateTime.fromISO('1999-12-31T23:45:56')
    .toLocaleString(DateTime.TIME_SIMPLE)
    .replace(/p\.m\./i, 'aa')
    .replace(/PM/i, 'aa')
    .replace(/45/, 'mm')
    .replace(/23/, 'HH')
    .replace(/11/, 'h')
);

// returns a mask for the Short Date format, to be used with react-number-format,
// for example ["M", "M", "D", "D", "Y", "Y", "Y", "Y"]
export const getShortDateMask = () => (
  getShortDatePlaceholder().replace(/[^YMD]/g, '').split('')
);


export const iso8601Date = (iso8601String) => iso8601String.substring(0, 10);

export const iso8601DateTime = (iso8601String) => iso8601String.substring(0, 19);

export const iso8601Time = (iso8601String) => iso8601String.substring(11, 16);

export const iso8601Year = (iso8601String) => iso8601String.substring(0, 4);

export const iso8601Month = (iso8601String) => iso8601String.substring(5, 7);

export const iso8601Day = (iso8601String) => iso8601String.substring(8, 10);

export const isEmptyIso8601Date = (iso8601String) => (
  !iso8601String || iso8601Date(iso8601String) === EMPTY_ISO8601_DATE
);

export const isValidIso8601Date = (iso8601String) => (
  typeof iso8601String === 'string' &&
  iso8601String.length === 10 &&
  DateTime.fromISO(iso8601String).isValid
);

export const isValidLocalDateString = (iso8601String) => (
  typeof iso8601String === 'string' &&
  iso8601String.length === 10 &&
  DateTime.fromFormat(iso8601String, getShortDateDatePickerFormat()).isValid
);

export const isIncompleteIso8601Date = (iso8601String) => (
  typeof iso8601String === 'string' &&
  iso8601String.length === 10 &&
  /[YMD]/.test(iso8601String)
);

// takes a HH:mm ISO8601 string and adds hours and minutes
export const iso8601TimeAddHoursAndMinutes = (iso8601TimeString, hours, minutes) => (
  iso8601Time(DateTime.fromISO(iso8601TimeString).plus({ hours, minutes }).toISO())
);

export const iso8601FromDate = (date, timeZone) => (
  DateTime.fromISO(iso8601DateTime(DateTime.fromJSDate(date).toISO()), { zone: timeZone }).toISO()
);

export const iso8601DateFromJSDate = (date, timeZone) => (
  iso8601Date(iso8601FromDate(date, timeZone))
);

export const iso8601FromDateInUTC = (date) => (
  DateTime.fromJSDate(date).setZone('UTC').toISO()
);

export const iso8601FromString = (iso8601String, timeZone) => (
  DateTime.fromISO(iso8601String, { zone: timeZone }).toISO()
);

export const iso8601FromStringInTimeZone = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toISO()
);

export const iso8601FromTimestamp = (milis) => (
  DateTime.fromMillis(milis).toISO()
);

export const iso8601FromLocalDateString = (iso8601Date_) => (
  iso8601Date(DateTime.fromFormat(iso8601Date_, getShortDateDatePickerFormat()).toISO())
);

export const iso8601Today = (timeZone) => (
  iso8601Date(DateTime.local().setZone(timeZone).toISO())
);

export const iso8601Now = (timeZone) => (
  DateTime.local({ zone: timeZone }).toISO()
);

export const iso8601NowInUTC = () => (
  DateTime.utc().toISO()
);

export const iso8601NowTime = (timeZone) => (
  iso8601Time(iso8601Now(timeZone))
);

export const iso8601MidnightInUTC = (iso8601Date_, timeZone) => (
  DateTime.fromISO(iso8601Date_, { zone: timeZone }).startOf('day').setZone('UTC').toISO()
);

export const iso8601MidnightTodayInUTC = (timeZone) => (
  DateTime.local().setZone(timeZone).startOf('day').setZone('UTC').toISO()
);

export const iso8601StringInUTC = (iso8601String, timeZone) => (
  DateTime.fromISO(iso8601String, { zone: timeZone }).setZone('UTC').toISO()
);

export const iso8601EodInUTC = (iso8601Date_, timeZone) => (
  DateTime.fromISO(iso8601Date_, { zone: timeZone }).endOf('day').setZone('UTC').toISO()
);

export const iso8601MidnightAWeekFromNowInUTC = (timeZone) => (
  DateTime.local().setZone(timeZone).startOf('day').plus({ days: 7 }).setZone('UTC').toISO()
);

export const formatIso8601Date = (iso8601String, timeZone) => (
  iso8601Date(iso8601FromString(iso8601String, timeZone))
);

export const formatIso8601Time = (iso8601String, timeZone) => (
  iso8601Time(iso8601FromString(iso8601String, timeZone))
);

export const formatDayOfMonth = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toLocaleString({ day: 'numeric' })
);

export const getDayOfWeek = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).weekday % 7
);

export const formatHour = (iso8601String, timeZone) => (
  // getLuxon(iso8601String, timeZone).toLocaleString({ hour: '2-digit', hour12: false })
  getLuxon(iso8601String, timeZone).toFormat('HH')
);

// formats an ISO8601 string as a short date, e.g. "10/14/1983"
export const formatShortDate = (iso8601String, timeZone, locale) => (
  getLuxon(iso8601String, timeZone, locale).toLocaleString(DateTime.DATE_SHORT)
);

export const formatShortDatePadded = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toFormat(getShortDateDatePickerFormat())
);

// formats an ISO8601 string as an abbreviated date but without the year, e.g. "Oct 14"
export const formatAbbrDateNoYear = (iso8601String, timeZone) => {
  const { year, ...DATE_MED_NO_YEAR } = DateTime.DATE_MED;

  return getLuxon(iso8601String, timeZone).toLocaleString(DATE_MED_NO_YEAR);
};

// formats an ISO8601 string as a full date, e.g. "October 14, 1983"
export const formatFullDate = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toLocaleString(DateTime.DATE_FULL)
);

// formats an ISO8601 string as a full date but without the year, e.g. "October 14"
export const formatFullDateNoYear = (iso8601String, timeZone) => {
  const { timeZoneName, ...DATE_FULL_NO_YEAR } = DateTime.DATE_FULL;

  return getLuxon(iso8601String, timeZone).toLocaleString(DATE_FULL_NO_YEAR);
};

// formats an ISO8601 string as a simple time, e.g. "1:30 PM"
export const formatSimpleTime = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toLocaleString(DateTime.TIME_SIMPLE)
);

// formats an ISO8601 string as a time with seconds, e.g. "1:30:23 PM"
export const formatTimeWithSeconds = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toLocaleString(DateTime.TIME_WITH_SECONDS)
);

// takes a hour number (e.g. 3) and outputs a simple time e.g. "3:00 AM"
export const formatSimpleTimeFromHour = (hour) => (
  DateTime.fromObject({ hour }).toLocaleString(DateTime.TIME_SIMPLE)
);

// formats an ISO8601 string as a short datetime, e.g. "10/14/1983, 1:30 PM"
export const formatShortDateTime = (iso8601String, timeZone, locale) => (
  getLuxon(iso8601String, timeZone, locale).toLocaleString(DateTime.DATETIME_SHORT)
);

// formats an ISO8601 string as a full datetime, e.g. "October 14, 1983, 1:30 PM"
export const formatFullDateTime = (iso8601String, timeZone) => {
  const { timeZoneName, ...DATETIME_FULL_NO_OFFSET } = DateTime.DATETIME_FULL;

  return getLuxon(iso8601String, timeZone).toLocaleString(DATETIME_FULL_NO_OFFSET);
};

export const formatDateInterval = (dateFormatter, iso8601Date1, iso8601Date2, timeZone) => {
  const f1 = iso8601Date1 ? dateFormatter(iso8601Date(iso8601Date1), timeZone) : '';
  const f2 = iso8601Date2 ? dateFormatter(iso8601Date(iso8601Date2), timeZone) : '';

  if (!iso8601Date1) {
    return '';
  }

  if (!iso8601Date2 || getLuxon(iso8601Date1).hasSame(getLuxon(iso8601Date2), 'day')) {
    return f1;
  }

  return dashJoin([f1, f2]);
};

export const formatShortTimezone = (timeZone) => (
  getLuxon(null, timeZone).toFormat('ZZZZ')
);

// example: Oct 14 – Dec 31
export const airbnbDateInterval = (iso8601Date1, iso8601Date2, timeZone) => (
  formatDateInterval(formatAbbrDateNoYear, iso8601Date1, iso8601Date2, timeZone)
);

// example: 10/14/1983 – 01/31/1984
export const shortDateInterval = (iso8601Date1, iso8601Date2, timeZone) => (
  formatDateInterval(formatShortDate, iso8601Date1, iso8601Date2, timeZone)
);

export const addDayInUTC = (iso8601String) => (
  DateTime.fromISO(iso8601String).plus({ days: 1 }).setZone('UTC').toISO()
);

export const addHoursAndMinutes = (iso8601String, hours, minutes) => (
  DateTime.fromISO(iso8601String).plus({ hours, minutes }).toISO()
);

export const firebaseKeyDayStart = (iso8601Date_, timeZone) => (
  iso8601DateTime(iso8601StringInUTC(iso8601Date_, timeZone))
);

export const firebaseKeyDayEnd = (iso8601Date_, timeZone) => (
  iso8601DateTime(iso8601EodInUTC(iso8601Date_, timeZone))
);

export const firebaseKeyTodayStart = (timeZone) => (
  firebaseKeyDayStart(iso8601Today(timeZone), timeZone)
);

export const firebaseKeyTodayEnd = (timeZone) => (
  firebaseKeyDayEnd(iso8601Today(timeZone), timeZone)
);

export const numberOfDaysAgo = (iso8601Date_, timeZone) => (
  Math.floor(getLuxon(null, timeZone).diff(getLuxon(iso8601Date_, timeZone), 'days').days)
);

export const numberOfDaysBetween = (iso8601Date1, iso8601Date2, timeZone) => (
  Math.floor(Math.abs(getLuxon(iso8601Date1, timeZone).diff(getLuxon(iso8601Date2, timeZone), 'days').days))
);

// age = the difference in years between a YYYY-MM-DD date and today
export const age = (iso8601Date_) => (
  Math.floor(getLuxon(null).diff(getLuxon(iso8601Date_), 'years').years)
);

export const setDayOnIso8601Date = (iso8601Date_, day) => (
  iso8601Date(DateTime.fromISO(iso8601Date_).set({ day }).toISO())
);

export const addDaysToIso8601Date = (iso8601Date_, days) => (
  iso8601Date(DateTime.fromISO(iso8601Date_).plus({ days }).toISO())
);

export const addWeeksToIso8601Date = (iso8601Date_, weeks) => (
  iso8601Date(DateTime.fromISO(iso8601Date_).plus({ weeks }).toISO())
);

export const addMonthsToIso8601Date = (iso8601Date_, months) => (
  iso8601Date(DateTime.fromISO(iso8601Date_).plus({ months }).toISO())
);

export const addYearsToIso8601Date = (iso8601Date_, years) => (
  iso8601Date(DateTime.fromISO(iso8601Date_).plus({ years }).toISO())
);

export const isBefore = (iso8601String1, iso8601String2, timeZone) => (
  getLuxon(iso8601String1, timeZone) < getLuxon(iso8601String2, timeZone)
);

export const isAfter = (iso8601String1, iso8601String2, timeZone) => (
  getLuxon(iso8601String1, timeZone) > getLuxon(iso8601String2, timeZone)
);

export const isInThePast = (iso8601String, timeZone) => (
  isBefore(iso8601String, null, timeZone)
);

// By "isSameDate" it is meant
// "do these two moments fall on the same YYYY-MM-DD date for the specified timezone"
export const isSameDate = (iso8601String1, iso8601String2, timeZone) => (
  getLuxon(iso8601String1, timeZone).hasSame(getLuxon(iso8601String2, timeZone), 'day')
);

// By "isSameMonth" it is meant
// "do these two moments fall on the same YYYY-MM month for the specified timezone"
export const isSameMonth = (iso8601String1, iso8601String2, timeZone) => (
  getLuxon(iso8601String1, timeZone).hasSame(getLuxon(iso8601String2, timeZone), 'month')
);

// By "isSameYear" it is meant
// "do these two moments fall on the same YYYY year for the specified timezone"
export const isSameYear = (iso8601String1, iso8601String2, timeZone) => (
  getLuxon(iso8601String1, timeZone).hasSame(getLuxon(iso8601String2, timeZone), 'year')
);

// By "isSameAm" it is meant
// "are these two moments both in the AM or in the PM for the specified timezone"
export const isSameAm = (iso8601String1, iso8601String2, timeZone) => {
  const h1 = getLuxon(iso8601String1, timeZone).hour;
  const h2 = getLuxon(iso8601String2, timeZone).hour;

  return (h1 < 12 && h2 < 12) || (h1 >= 12 && h2 >= 12);
};

export const isInTheLast24h = (iso8601String, timeZone) => {
  const diff = getLuxon(null, timeZone).diff(getLuxon(iso8601String, timeZone));

  return diff >= 0 && diff <= DAY;
};

export const isToday = (iso8601String, timeZone) => (
  isSameDate(iso8601String, null, timeZone)
);

export const relativeDateTime = (iso8601String, timeZone) => {
  const i18n = i18nService.getI18nInstance();
  const diff = numberOfDaysAgo(iso8601String, timeZone);
  const datePortion = (
    diff === 0
      ? capital(i18n.t('calendar.today'))
      : diff === 1
        ? capital(i18n.t('calendar.today'))
        : capital(i18n.t('calendar._on_date', { date: formatAbbrDateNoYear(iso8601String, timeZone) }))
  );
  const timePortion = i18n.t('calendar._at_time', { time: formatSimpleTime(iso8601String, timeZone) });

  return `${datePortion} ${timePortion}`;
};

export const formatSimpleCalEventTime = (timeString) => (
  formatSimpleTime(DateTime.fromFormat(timeString, 'HH:mm'))
);

// builds a UTC Date() from a given local Date()
export const rruleDateFromDate = (date) => (
  new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
);

// builds a UTC Date() from a given ISO8601 Date
export const rruleDateFromIso8601Date = (iso8601Date_) => new Date(Date.UTC(
  Number(iso8601Year(iso8601Date_)),
  Number(iso8601Month(iso8601Date_)) - 1,
  Number(iso8601Day(iso8601Date_)),
));

// computes difference in hours and minutes between two Date()s
export const calendarEventDuration = (date1, date2) => {
  const diffDurationInMs = date2.getTime() - date1.getTime();
  const diffDurationInMinutes = diffDurationInMs / MINUTE;
  const diffMinutes = diffDurationInMinutes % 60;
  const diffHours = (diffDurationInMinutes - diffMinutes) / 60;

  return `${leftPadZero(diffHours, 2)}H${leftPadZero(diffMinutes, 2)}M`;
};

export const prevDayAtMidnight = (iso8601Date_, timeZone) => (
  iso8601DateTime(DateTime.fromISO(iso8601Date_, { zone: timeZone }).plus({ days: -1 }).toISO())
);

// finds the next "quarter start" (:00, :15, :30, :45) after the current moment
export const calendarNextQuarterStart = () => {
  const now = Date.now();
  const mr = now % HOUR;
  const mr2 = (
    mr > 45 * MINUTE
      ? 60 * MINUTE
      : mr > 30 * MINUTE
        ? 45 * MINUTE
        : mr > 15 * MINUTE
          ? 30 * MINUTE
          : mr > 0
            ? 15 * MINUTE
            : 0
  );

  return new Date(now - mr + mr2);
};

// returns a Date() corresponding to midnight today in the local timezone
export const dateMidnightToday = () => (
  new Date().setHours(0, 0, 0, 0)
);

// takes a Date() and adds hours and minutes
export const dateAddHoursAndMinutes = (date, hours, minutes) => (
  new Date(date.getTime() + hours * HOUR + minutes * MINUTE)
);

// takes a Date() and adds 30 minutes
export const dateHalfHourLater = (date) => dateAddHoursAndMinutes(date, 0, 30);

export const dateAddDiffBetweenDates = (date, date1, date2) => (
  new Date(date.getTime() + date2.getTime() - date1.getTime())
);

// checks if two Dates() fall on the same day/month/year
export const datesAreSameDay = (date1, date2) => (
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate()
);

// Takes a Date() and returns the HH:MM part of it.
// No timezone, used for DatePickers that work with a Date()
export const dateHoursAndMinutes = (date) => (
  `${leftPadZero(date.getHours(), 2)}:${leftPadZero(date.getMinutes(), 2)}`
);

// Takes a Date() and returns the YYYY-MM-DD part of it.
// No timezone, used for DatePickers that work with a Date()
export const dateYearMonthDay = (date) => (
  `${date.getFullYear()}-${leftPadZero(date.getMonth() + 1, 2)}-${leftPadZero(date.getDate(), 2)}`
);

export const calEventDate = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone).toLocaleString({
    month: 'long',
    day: 'numeric',
    weekday: 'long',
  })
);

// January 1 - February 2, 1984
export const calEventDateInterval = (iso8601String1, iso8601String2, timeZone) => {
  const s1 = (
    isSameYear(iso8601String1, iso8601String2, timeZone)
      ? formatFullDateNoYear(iso8601String1, timeZone)
      : formatFullDate(iso8601String1, timeZone)
  );
  const s2 = formatFullDate(iso8601String2, timeZone);

  return dashJoin([s1, s2]);
};

// 1:30 PM - 3:30 PM
export const calEventTimeInterval = (iso8601String1, iso8601String2, timeZone) => {
  const s1 = formatSimpleTime(iso8601String1, timeZone);
  const s2 = formatSimpleTime(iso8601String2, timeZone);

  return dashJoin([s1, s2]);
};

export const calEventDateTimeInterval = (iso8601String1, iso8601String2, timeZone) => {
  const s1 = formatFullDateTime(iso8601String1, timeZone);
  const s2 = formatFullDateTime(iso8601String2, timeZone);

  return dashJoin([s1, s2]);
};

export const dateTimeInterval = (iso8601String1, iso8601String2, timeZone) => {
  const s1 = formatShortDateTime(iso8601String1, timeZone);
  const s2 = formatShortDateTime(iso8601String2, timeZone);

  return dashJoin([s1, s2]);
};

export const localDateFromIso8601String = (iso8601String, timeZone = 'local') => (
  DateTime.fromISO(iso8601DateTime(DateTime.fromISO(iso8601String, { zone: timeZone }).toISO())).toJSDate()
  // DateTime.fromISO(iso8601String, { zone: timeZone }).toJSDate()
);

// Takes a YYYY-MM-DDTHH:mm:ss DateTime string and computes a Date() in the local timezone
export const localDateFromIso8601DateTime = (iso8601String, timeZone = 'local') => (
  localDateFromIso8601String(iso8601DateTime(iso8601String), timeZone)
);

// Takes a YYYY-MM-DD Date string and computes a Date() in the local timezone
export const localDateFromIso8601Date = (iso8601String, timeZone = 'local') => (
  localDateFromIso8601String(iso8601Date(iso8601String), timeZone)
);

// Takes a HH:mm string and computes a Date() in the local timezone (the date part is set to today)
export const localDateFromIso8601Time = (timeString) => (
  DateTime.fromFormat(timeString, 'HH:mm').toJSDate()
);

// computes difference in hours and minutes between two HH:mm times
export const bookingDuration = (time1, time2) => {
  const date1 = localDateFromIso8601Time(time1);
  const date2 = localDateFromIso8601Time(time2);
  const date3 = date2 < date1 ? dateAddHoursAndMinutes(date2, 24, 0) : date2;

  return calendarEventDuration(date1, date3);
};

export const yearsAndDaysLeft = (iso8601String, timeZone) => (
  getLuxon(iso8601String, timeZone)
    .diff(getLuxon(null, timeZone), ['years', 'days'])
    .toHuman({ maximumFractionDigits: 0, roundingMode: 'floor' })
);

export const composeIso8601DateTime = (iso8601Date_, iso8601Time_) => (
  `${isEmptyIso8601Date(iso8601Date_)
    ? EMPTY_ISO8601_DATE
    : iso8601Date_}T${!iso8601Time_ ? EMPTY_ISO8601_TIME : iso8601Time_}`
);

export const shortHumanDuration = (iso8601String, timeZone = 'local') => {
  const diff = getLuxon(null, timeZone).diff(DateTime.fromISO(iso8601String));
  const ds = diff.as('seconds');
  if (ds < 1) return 'Now';
  if (ds < 60) return `${Math.floor(ds)}s`;
  const dm = diff.as('minutes');
  if (dm < 60) return `${Math.floor(dm)}m`;
  const dh = diff.as('hours');
  if (dh < 24) return `${Math.floor(dh)}h`;
  const dd = diff.as('days');
  const dmo = diff.as('months');
  if (dmo < 1) return `${Math.floor(dd)}d`;
  if (dmo < 12) return `${Math.floor(dmo)}mo`;
  const dy = diff.as('years');
  return `${Math.floor(dy)}y`;
};
