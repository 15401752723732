// eslint-disable-next-line import/prefer-default-export
export const REPORT_HEADERS = [
  {
    label: 'Revenue Categories',
    key: 'revenueCategoryName',
  },
  {
    label: 'Cash Sale',
    key: 'totalCashSale',
  },
  {
    label: 'Cash Local Tax',
    key: 'totalCashSaleLocaleTax',
  },
  {
    label: 'Cash City Tax',
    key: 'totalCashSaleCityTax',
  },
  {
    label: 'Cash State Tax',
    key: 'totalCashSaleStateTax',
  },
  {
    label: 'Cash Additional Tax',
    key: 'totalCashSaleAdditionalTax',
  },
  {
    label: 'Cash Tax Total',
    key: 'totalCashSaleTax',
  },
  {
    label: 'Cash Total',
    key: 'totalCashSaleTaxInclusive',
  },
  {
    label: 'Credit Sale',
    key: 'totalCreditSale',
  },
  {
    label: 'Credit Local Tax',
    key: 'totalCreditSaleLocaleTax',
  },
  {
    label: 'Credit City Tax',
    key: 'totalCreditSaleCityTax',
  },
  {
    label: 'Credit State Tax',
    key: 'totalCreditSaleStateTax',
  },
  {
    label: 'Credit Additional Tax',
    key: 'totalCreditSaleAdditionalTax',
  },
  {
    label: 'Credit Tax Total',
    key: 'totalCreditSaleTax',
  },
  {
    label: 'Credit Total',
    key: 'totalCreditSaleTaxInclusive',
  },
  {
    label: 'Gift Card Redemption',
    key: 'totalGiftcardRedemption',
  },
  {
    label: 'Gift Card Redemption Local Tax',
    key: 'totalGiftcardRedemptionLocaleTax',
  },
  {
    label: 'Gift Card Redemption City Tax',
    key: 'totalGiftcardRedemptionCityTax',
  },
  {
    label: 'Gift Card Redemption State Tax',
    key: 'totalGiftcardRedemptionStateTax',
  },
  {
    label: 'Gift Card Redemption Additional Tax',
    key: 'totalGiftcardRedemptionAdditionalTax',
  },
  {
    label: 'Gift Card Redemption Tax Total',
    key: 'totalGiftcardRedemptionTax',
  },
  {
    label: 'Gift Card Redemption Total',
    key: 'totalGiftcardRedemptionTaxInclusive',
  },
  {
    label: 'Ach Sale',
    key: 'totalAchSale',
  },
  {
    label: 'Ach Sale Local Tax',
    key: 'totalAchSaleLocaleTax',
  },
  {
    label: 'Ach Sale City Tax',
    key: 'totalAchSaleCityTax',
  },
  {
    label: 'Ach Sale State Tax',
    key: 'totalAchSaleStateTax',
  },
  {
    label: 'Ach Sale Additional Tax',
    key: 'totalAchSaleAdditionalTax',
  },
  {
    label: 'Ach Tax Total',
    key: 'totalAchSaleTax',
  },
  {
    label: 'Ach Total',
    key: 'totalAchTaxInclusive',
  },
  {
    label: 'Store Credit Sale',
    key: 'totalStoreCreditSale',
  },
  {
    label: 'Store Credit Sale Local Tax',
    key: 'totalStoreCreditSaleLocaleTax',
  },
  {
    label: 'Store Credit Sale City Tax',
    key: 'totalStoreCreditSaleCityTax',
  },
  {
    label: 'Store Credit Sale State Tax',
    key: 'totalStoreCreditSaleStateTax',
  },
  {
    label: 'Store Credit Sale Additional Tax',
    key: 'totalStoreCreditSaleAdditionalTax',
  },
  {
    label: 'Store Credit Tax Total',
    key: 'totalStoreCreditSaleTax',
  },
  {
    label: 'Store Credit Total',
    key: 'totalStoreCreditSaleTaxInclusive',
  },
  {
    label: 'Cash Refund',
    key: 'totalCashRefund',
  },
  {
    label: 'Cash Refund Local Tax',
    key: 'totalCashRefundLocaleTax',
  },
  {
    label: 'Cash Refund City Tax',
    key: 'totalCashRefundCityTax',
  },
  {
    label: 'Cash Refund State Tax',
    key: 'totalCashRefundStateTax',
  },
  {
    label: 'Cash Refund Additional Tax',
    key: 'totalCashRefundAdditionalTax',
  },
  {
    label: 'Cash Refund Tax Total',
    key: 'totalCashRefundTax',
  },
  {
    label: 'Cash Refund Total',
    key: 'totalCashRefundTaxInclusive',
  },
  {
    label: 'Credit Refund',
    key: 'totalCreditRefund',
  },
  {
    label: 'Credit Refund Local Tax',
    key: 'totalCreditRefundLocaleTax',
  },
  {
    label: 'Credit Refund City Tax',
    key: 'totalCreditRefundCityTax',
  },
  {
    label: 'Credit Refund State Tax',
    key: 'totalCreditRefundStateTax',
  },
  {
    label: 'Credit Refund Additional Tax',
    key: 'totalCreditRefundAdditionalTax',
  },
  {
    label: 'Credit Refund Tax Total',
    key: 'totalCreditRefundTax',
  },
  {
    label: 'Credit Refund Total',
    key: 'totalCreditRefundTaxInclusive',
  },
  {
    label: 'Gift Card Refund',
    key: 'totalGiftcardRefund',
  },
  {
    label: 'Giftcard Refund Local Tax',
    key: 'totalGiftcardRefundLocaleTax',
  },
  {
    label: 'Giftcard Refund City Tax',
    key: 'totalGiftcardRefundCityTax',
  },
  {
    label: 'Giftcard Refund State Tax',
    key: 'totalGiftcardRefundStateTax',
  },
  {
    label: 'Giftcard Refund Additional Tax',
    key: 'totalGiftcardRefundAdditionalTax',
  },
  {
    label: 'Gift Card Refund Tax Total',
    key: 'totalGiftcardRefundTax',
  },
  {
    label: 'Gift Card Refund Total',
    key: 'totalGiftcardRefundTaxInclusive',
  },
  {
    label: 'Ach Refund',
    key: 'totalAchRefund',
  },
  {
    label: 'ACH Refund Local Tax',
    key: 'totalAchRefundLocaleTax',
  },
  {
    label: 'ACH Refund City Tax',
    key: 'totalAchRefundCityTax',
  },
  {
    label: 'ACH Refund State Tax',
    key: 'totalAchRefundStateTax',
  },
  {
    label: 'ACH Refund Additional Tax',
    key: 'totalAchRefundAdditionalTax',
  },
  {
    label: 'Ach Refund Tax Total',
    key: 'totalAchRefundTax',
  },
  {
    label: 'Ach Refund Total',
    key: 'totalAchRefundTaxInclusive',
  },
  {
    label: 'Store Credit Refund',
    key: 'totalStoreCreditRefund',
  },
  {
    label: 'Store Credit Refund Local Tax',
    key: 'totalStoreCreditRefundLocaleTax',
  },
  {
    label: 'Store Credit Refund City Tax',
    key: 'totalStoreCreditRefundCityTax',
  },
  {
    label: 'Store Credit Refund State Tax',
    key: 'totalStoreCreditRefundStateTax',
  },
  {
    label: 'Store Credit Refund Additional Tax',
    key: 'totalStoreCreditRefundAdditionalTax',
  },
  {
    label: 'Store Credit Refund Tax Total',
    key: 'totalStoreCreditRefundTax',
  },
  {
    label: 'Store Credit Refund Total',
    key: 'totalStoreCreditRefundTaxInclusive',
  },
  {
    label: 'Cash Net Sale',
    key: 'totalSumCashSale',
  },
  {
    label: 'Cash Net Local Tax',
    key: 'totalSumCashLocaleTax',
  },
  {
    label: 'Cash Net City Tax',
    key: 'totalSumCashCityTax',
  },
  {
    label: 'Cash Net State Tax',
    key: 'totalSumCashStateTax',
  },
  {
    label: 'Cash Net Additional Tax',
    key: 'totalSumCashAdditionalTax',
  },
  {
    label: 'Cash Net Tax Total',
    key: 'totalSumCashTax',
  },
  {
    label: 'Cash Net Total',
    key: 'totalSumCashTaxInclusive',
  },
  {
    label: 'Credit Net Sale',
    key: 'totalSumCreditSale',
  },
  {
    label: 'Credit Net Local Tax',
    key: 'totalSumCreditLocaleTax',
  },
  {
    label: 'Credit Net City Tax',
    key: 'totalSumCreditCityTax',
  },
  {
    label: 'Credit Net State Tax',
    key: 'totalSumCreditStateTax',
  },
  {
    label: 'Credit Net Additional Tax',
    key: 'totalSumCreditAdditionalTax',
  },
  {
    label: 'Credit Net Tax Total',
    key: 'totalSumCreditTax',
  },
  {
    label: 'Credit Net Total',
    key: 'totalSumCreditTaxInclusive',
  },
  {
    label: 'Gift Card Net Sale',
    key: 'totalSumGiftcardSale',
  },
  {
    label: 'Gift Card Net Local Tax',
    key: 'totalSumGiftcardLocaleTax',
  },
  {
    label: 'Gift Card Net City Tax',
    key: 'totalSumGiftcardCityTax',
  },
  {
    label: 'Gift Card Net State Tax',
    key: 'totalSumGiftcardStateTax',
  },
  {
    label: 'Gift Card Net Additional Tax',
    key: 'totalSumGiftcardAdditionalTax',
  },
  {
    label: 'Gift Card Net Tax Total',
    key: 'totalSumGiftcardTax',
  },
  {
    label: 'Gift Card Net Total',
    key: 'totalSumGiftcardTaxInclusive',
  },
  {
    label: 'Ach Net Sale',
    key: 'totalSumAchSale',
  },
  {
    label: 'Ach Net Local Tax',
    key: 'totalSumAchLocaleTax',
  },
  {
    label: 'Ach Net City Tax',
    key: 'totalSumAchCityTax',
  },
  {
    label: 'Ach Net State Tax',
    key: 'totalSumAchStateTax',
  },
  {
    label: 'Ach Net Additional Tax',
    key: 'totalSumAchAdditionalTax',
  },
  {
    label: 'Ach Net Tax Total',
    key: 'totalSumAchTax',
  },
  {
    label: 'Ach Net Total',
    key: 'totalSumAchTaxInclusive',
  },
  {
    label: 'Store Credit Net Sale',
    key: 'totalSumStoreCreditSale',
  },
  {
    label: 'Store Credit Net Local Tax',
    key: 'totalSumStoreCreditLocaleTax',
  },
  {
    label: 'Store Credit Net City Tax',
    key: 'totalSumStoreCreditCityTax',
  },
  {
    label: 'Store Credit Net State Tax',
    key: 'totalSumStoreCreditStateTax',
  },
  {
    label: 'Store Credit Net Additional Tax',
    key: 'totalSumStoreCreditAdditionalTax',
  },
  {
    label: 'Store Credit Net Tax Total',
    key: 'totalSumStoreCreditTax',
  },
  {
    label: 'Store Credit Net Total',
    key: 'totalSumStoreCreditTaxInclusive',
  },
  {
    label: 'Local Tax',
    key: 'localeTax',
  },
  {
    label: 'City Tax',
    key: 'cityTax',
  },
  {
    label: 'State Tax',
    key: 'stateTax',
  },
  {
    label: 'Additional Tax',
    key: 'additionalTax',
  },
  {
    label: 'Tax Total',
    key: 'tax',
  },
  {
    label: 'Tax Revenue',
    key: 'totalTaxableRevenue',
  },
  {
    label: 'Non-Taxable Revenue',
    key: 'totalNonTaxableRevenue',
  },
  {
    label: 'Gross Receipts',
    key: 'total',
  },
  {
    label: 'Total Revenue',
    key: 'totalAmount',
  },
  {
    label: 'Gross Receipts excluding Gift Card Redemption',
    key: 'totalWithoutGiftcardRedemption',
  },
  {
    label: 'Total Revenue excluding Gift Card Redemption',
    key: 'amountWithoutGiftcardRedemption',
  },
  {
    label: 'Taxes excluding Gift Card Redemption',
    key: 'taxWithoutGiftcardRedemption',
  },
];
