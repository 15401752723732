export const DATES = {
  ALL: 'all',
  PAST: 'past',
  CURRENT: 'current',
};

export const DATES_LIST = [
  { label: 'All', value: DATES.ALL },
  { label: 'Past', value: DATES.PAST },
  { label: 'Current', value: DATES.CURRENT },
];
