import React from 'react';
import { PropTypes } from 'prop-types';

import { TICKET_STATUS_PICKLIST_VALUES } from 'constants/ticket';
import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const STATUS_PICKLIST_VALUES = [
  {
    label: 'All',
    value: 0,
  },
  ...TICKET_STATUS_PICKLIST_VALUES.map((ticketStatus) => ({
    label: ticketStatus.name,
    value: ticketStatus.value,
  })),
];


class TicketStatusFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ticketStatus: (
        this.props.hasOwnProperty('status') && this.props.status !== 'null'
          ? this.props.status
          : 0
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['ticketStatus'])) {
      this.handleValueChange();
    }
  }

  handleStatusChange = (update) => {
    if (!update.hasOwnProperty('value')) return;

    this.setState({
      ticketStatus: update.value,
    });
  };

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  getOwnFilterMap = () => ({
    status: this.state.ticketStatus === 0 ? null : this.state.ticketStatus,
  });

  render() {
    const { ticketStatus } = this.state;

    return (
      <PicklistField
        label="Status(es)"
        className="ticket-status-filter"
        value={ticketStatus}
        onChange={this.handleStatusChange}
        picklistValues={STATUS_PICKLIST_VALUES}
      />
    );
  }
}

TicketStatusFilter.propTypes = {
  onValueChange: PropTypes.func,
  status: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

TicketStatusFilter.ownName = 'TicketStatusFilter';
TicketStatusFilter.ownFilters = ['status'];


export default TicketStatusFilter;
