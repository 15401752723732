import React from 'react';
import { PropTypes } from 'prop-types';

import { DATES, DATES_LIST } from 'constants/reservations';
import { somePropsChanged } from 'service/utility';

import { PicklistField } from '../../formFields';


const DATE_PICKLIST_VALUES = [
  ...DATES_LIST.map((date) => ({
    label: date.label,
    value: date.value,
  })),
];

class ReservationDateFilter extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      date: (
        this.props.hasOwnProperty('when')
          ? this.props.when !== 'null'
            ? this.props.when
            : DATES.CURRENT
          : DATES.CURRENT
      ),
    };
  }

  componentDidMount() {
    this.handleValueChange();
  }

  componentDidUpdate(prevProps, prevState) {
    if (somePropsChanged(prevState, this.state, ['date'])) {
      this.handleValueChange();
    }
  }

  handleDateChange = (update) => {
    if (!update.hasOwnProperty('value')) return;

    this.setState({
      date: update.value,
    });
  };

  handleValueChange = () => {
    this.props.onValueChange(
      this.constructor.ownName,
      this.getOwnFilterMap(),
    );
  };

  getOwnFilterMap = () => ({
    when: this.state.date === 0 ? null : this.state.date,
  });

  render() {
    const { date } = this.state;

    return (
      <PicklistField
        label="Date"
        value={date}
        onChange={this.handleDateChange}
        picklistValues={DATE_PICKLIST_VALUES}
      />
    );
  }
}

ReservationDateFilter.propTypes = {
  onValueChange: PropTypes.func,
  when: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

ReservationDateFilter.ownName = 'ReservationDateFilter';
ReservationDateFilter.ownFilters = ['when'];

export default ReservationDateFilter;
