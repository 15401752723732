import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import { Picky } from 'react-picky';
import {
  FormControl, FormGroup, FormHelperText, InputLabel, Checkbox, Typography,
} from '@material-ui/core';

import { isRequired } from 'service/utility/errorMessages';

import { Spinner } from '../../statusIndicators';


class Checkboxes extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      blurred: false,
      loading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;

    this.setOptions();

    const { value, onChange } = this.props;
    const error = this.validateSelf(value);

    if (Boolean(onChange) && Boolean(error)) {
      onChange({ error });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.getOptionsAPI !== this.props.getOptionsAPI ||
      prevProps.getParams !== this.props.getParams
    ) {
      this.setOptions();
    }

    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const { value, onChange } = this.props;
      const error = this.validateSelf(value);

      if (onChange) {
        onChange({ error });
      }
    }
  }

  componentWillUnmount() {
    const { onChange } = this.props;

    if (onChange) {
      onChange({ error: null });
    }

    this._isMounted = false;
  }

  setOptions = async () => {
    const { getOptionsAPI, getParams, validOptionIds } = this.props;

    const { data } = await getOptionsAPI(...getParams);
    const allOptions = data ? (data.data || data) : [];

    const validOptions = (
      validOptionIds.length
        ? allOptions.filter((e) => validOptionIds.includes(e.id))
        : allOptions
    );

    if (this._isMounted) {
      this.setState({
        loading: false,
        options: validOptions,
      });
    }
  };

  validateSelf = (value) => {
    const { required, label } = this.props;

    if (!value.length && required) return isRequired(label);

    return null;
  };

  getMultiValue = () => this.state.options.filter((e) => this.props.value.includes(e.id));

  setMultiValue = (newMultiValue) => {
    const { onChange } = this.props;

    const value = newMultiValue.map((e) => e.id);
    const error = this.validateSelf(value);

    this.setState({
      blurred: true,
    });

    if (onChange) {
      onChange({ value, error });
    }
  };

  renderItem = ({ style, isSelected, item, selectValue, labelKey, valueKey }) => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <li
      style={style}
      className={isSelected ? 'option selected' : 'option'}
      key={item[valueKey]}
      onClick={this.props.fixedOptions.includes(item[valueKey]) ? null : () => selectValue(item)}
    >
      <Checkbox
        checked={isSelected}
        inputProps={{
          readOnly: true,
        }}
        disabled={this.props.fixedOptions.includes(item[valueKey])}
      />
      <Typography component="span">
        {item[labelKey]}
      </Typography>
    </li>
  );

  renderSelectAll = ({ tabIndex, allSelected, toggleSelectAll }) => (
    this.props.fixedOptions.length > 0 ? (
      null
    ) : (
      <div
        tabIndex={tabIndex}
        role="option"
        aria-selected={allSelected === 'all'}
        className={allSelected ? 'option selected' : 'option'}
        onClick={toggleSelectAll}
        onKeyPress={toggleSelectAll}
      >
        <Checkbox
          checked={allSelected === 'all'}
          indeterminate={allSelected === 'partial'}
          inputProps={{
            readOnly: true,
          }}
        />
        <Typography component="span">
          {'Select All'}
        </Typography>
      </div>
    )
  );

  render() {
    const { label, error, required, className, fullWidth } = this.props;
    const { loading, options, blurred } = this.state;

    const relatedCheckboxesClassName = classnames(
      'checkboxes',
      { 'w-100': fullWidth, [className]: Boolean(className) },
    );
    const multiValue = this.getMultiValue();
    const showError = blurred && Boolean(error);

    return loading ? (
      <Spinner />
    ) : (
      <FormControl
        className={relatedCheckboxesClassName}
        component="fieldset"
        required={required}
        error={showError}
      >
        {label && (
          <InputLabel
            error={showError}
            className="input-label"
            shrink
            variant="outlined"
          >
            {label}
          </InputLabel>
        )}
        <FormGroup>
          <Picky
            options={options}
            labelKey="name"
            valueKey="id"
            multiple
            includeSelectAll
            value={multiValue}
            onChange={this.setMultiValue}
            render={this.renderItem}
            renderSelectAll={this.renderSelectAll}
          />
        </FormGroup>
        <FormHelperText
          margin="dense"
          variant="outlined"
        >
          {error}
        </FormHelperText>
      </FormControl>
    );
  }
}

Checkboxes.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  fixedOptions: PropTypes.array,
  fullWidth: PropTypes.bool,
  getOptionsAPI: PropTypes.func.isRequired,
  getParams: PropTypes.array,
  label: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  validOptionIds: PropTypes.array,
  value: PropTypes.array,
};

Checkboxes.defaultProps = {
  error: '',
  fixedOptions: [],
  fullWidth: false,
  getParams: [],
  required: false,
  validOptionIds: [],
  value: [],
};


export default Checkboxes;
