export const IS_ACTIVE_TOOLTIP = `
  Available for purchase. This must be checked to purchase in the main app or the portal as well.
`;
export const FOR_SALE_USE_ONLINE_TOOLTIP = `
  When selected, the public will be able to view, click and buy this.
  After this is purchased by a customer, the assigned customer will be able to interact with this on your portal.
`;
export const FOR_USE_ONLINE_ONLY_TOOLTIP = `
  When selected, the public will not be able to view, click and buy this.
  However, if this is owned by a customer, the assigned customer will be able to interact with this on your portal.
  Inactive or active membership/pass types that have been purchased previously will be usable in the portal if this is checked as long as the membership itself is active.
`;
export const GUEST_PASSES_TOOLTIP = `
  Guest Passes will update on the subsequent billing day.
`;
export const ALL_DAY_PASS_TOOLTIP = `
  Check this to allwo unlimited check-ins at applicable events at all participating locations in the same business day.
`;
export const AGE_REQUIREMENT_TOOLTIP = `
  Please note: if there is a minimum/maximum age set and the customer does not meet the age requirements, their check-in will fail.
`;
export const PURCHASE_MEMBERSHIP_OR_PASS = `
  Purchase Membership or Pass
`;
export const DO_NOT_PRORATE_TOOLTIP = `
  Selecting this option will ensure that this membership, when purchased from the portal, will always be purchased at full price instead of the prorated amount.
`;
export const CERTIFICATION_IS_ACTIVE_TOOLTIP = `
  If unchecked, the certification will not appear in the certification list anymore (unless the filter is adjusted to include inactive certifications.)
  If a certification is inactive, customers can no longer be assigned this certification.
  However, customers which already have this certification will continue having this certification.
`;
export const LOCATION_IS_ACTIVE_TOOLTIP = `
  If unchecked, the location will not appear in the location list anymore (unless the filter is adjusted to include inactive locations.)
  Setting a location to inactive retains the location and all of its related information(events, memberships, passes, etc.)
  But it can not longer be accessed from the change location dialog.
`;
export const PRODUCT_IS_ACTIVE_TOOLTIP = `
  If unchecked, the product will not appear in the product list anymore (unless the filter is adjusted to include inactive products.)
  Setting a product to inactive makes it no longer sellable.
`;
export const PASS_TYPE_IS_ACTIVE_TOOLTIP = `
  If unchecked, the pass type will not appear in the pass type list anymore (unless the filter is adjusted to include inactive pass types.)
  Setting a pass type to inactive makes it no longer sellable.
  However, customers that already have a pass of this type will continue having it and can use it as a check-in method.
`;
export const MEMBERSHIP_TYPE_IS_ACTIVE_TOOLTIP = `
  If unchecked, the membership type will not appear in the membership type list anymore (unless the filter is adjusted to include inactive membership types.)
  Setting a membership type to inactive makes it no longer sellable.
  However, customers that already have a membership of this type will continue having it and can use it as a check-in method.
`;
export const GIFT_CARD_TYPE_IS_ACTIVE_TOOLTIP = `
  If unchecked, the gift card type will not appear in the gift card type list anymore (unless the filter is adjusted to include inactive gift card types.)
  Setting a gift card type to inactive makes it no longer sellable.
  However, customers that already have a gift card of this type will continue having it and can use it as a payment method.
`;
export const VARIANT_TYPE_IS_ACTIVE_TOOLTIP = `
  If unchecked, the variant type will not appear in the variant type list anymore (unless the filter is adjusted to include inactive variant types.)
  Setting a variant type to inactive makes it no longer assignable to products.
  However, products which already have this variant type will continue having it and are still sellable.
`;
export const REVENUE_CATEGORY_IS_ACTIVE_TOOLTIP = `
  If unchecked, the revenue category will not appear in the revenue category list anymore (unless the filter is adjusted to include inactive revenue categories.)
  Setting a revenue category to inactive makes it longer selectable on membership types/pass types etc.
  However, inactive revenue categories will continue to display in revenue category reports, QuickBooks mappings and the cash drawer.
`;
export const CALENDAR_IS_ACTIVE_TOOLTIP = `
  When active, Calendars can be used for scheduling events or activities. When inactive, calendars will be hidden.
`;
export const PRICING_TIER_IS_ACTIVE_TOOLTIP = `
  If unchecked, the pricing tier will not appear in the pricing tier list anymore (unless the filter is adjusted to include inactive pricing tiers.)
  Setting a pricing tier to inactive makes it no longer assignable to events.
  However, events which already have this pricing tier will continue having it.
`;
export const TICKET_TYPE_IS_ACTIVE_TOOLTIP = `
  If unchecked, the ticket type will not appear in the ticket type list anymore (unless the filter is adjusted to include inactive ticket types.)
  Setting a ticket types to inactive makes it no longer assignable to events.
  However, events which already have this ticket type will continue having it.
`;
export const CATEGORY_IS_ACTIVE_TOOLTIP = `
  If unchecked, the category will not appear in the category list anymore (unless the filter is adjusted to include inactive categories.)
  Setting a category to inactive makes it no longer assignable to events.
  However, events which already have this category will continue having it.
`;
export const CATEGORY_IS_PUBLIC_TOOLTIP = `
  When selected, the public will be able to view this category, and all events associated with it from the customer portal; Please note: deactivating a category won't deactivate asociated events.
`;
export const RESUME_DATE_TOOLTIP = `
  By setting the resume date, this membership record will be added to the resume list under CRM. This list helps staff on a daily basis to identify which memberships need to be resumed and then resume them.
`;
export const MAX_CHECKINS_TOOLTIP = `
  This field sets the maximum amount of check-ins that can be done with this membership type per customer per billing period. If left blank, an unlimited amount of check-ins can be done per billing period.
`;
export const PRICE_UPDATE_MEMBERSHIP_TYPE_TOOLTIP = `
  If you change the price of a membership type, it will only effect memberships that are sold after the change. All memberships that were sold before the price change will still charge the associated customers with the original price. Please submit a data update request to update current members to the new price as well.
`;
export const MEMBERSHIP_PRE_SALE_TOOLTIP = `
  When checked on the portal checkout screen, the “Detailed Billing Info” is hidden for this membership type to prevent confusion about how this membership type will be billed.
  Also when checked, do not prorate is automatically checked as well.
`;
export const CUSTOM_TAXES_TOOLTIP = `
  If the tax field is left blank, the location tax setting will be used. Use zero tax if you do not want to use the location tax setting.
`;
export const DISABLED_TAXES_TOOLTIP = `
  Product tax settings are disabled due to product variants being enabled. Please update product variant settings.
`;
export const REDEEM_POINTS = `
  Redeem points for products or passes
`;
export const REDIRECT_IFRAME_TRACKING_TOOLTIP = `
  This URL allows redirection of all Google Tag Manager (GTM) tags through a safe domain when embedding the customer portal into a website. Please note, it is the client's responsibility to set up a listener on their parent site to process the payload and forward it to GTM for tracking.
`;
