import { PERMISSIONS, SECTIONS, SUB_SECTIONS } from './permissions';


const STAFF_ROLES = ['STAFF', 'ADMIN', 'OWNER'];

export const STAFF_ROLE_IDS = {
  STAFF: 2,
  ADMIN: 1,
  OWNER: 3,
};

export const STAFF_ROLE_LABELS = {
  STAFF: 'Staff',
  ADMIN: 'Admin',
  OWNER: 'Owner',
};

export const STAFF_PRIVILEGES = {
  STAFF: 1,
  ADMIN: 2,
  OWNER: 3,
};

export const STAFF_ROLE_TO_LABEL = STAFF_ROLES.reduce((a, b) => {
  a[STAFF_ROLE_IDS[b]] = STAFF_ROLE_LABELS[b];

  return a;
}, {});

export const STAFF_ROLE_TO_PRIVILEGE = STAFF_ROLES.reduce((a, b) => {
  a[STAFF_ROLE_IDS[b]] = STAFF_PRIVILEGES[b];

  return a;
}, {});

export const STAFF_ROLE_PICKLIST_VALUES = (
  STAFF_ROLES
    .filter((e) => e !== 'OWNER')
    .map((e) => ({
      label: STAFF_ROLE_LABELS[e],
      value: STAFF_ROLE_IDS[e],
    }))
);

export const STAFF_ROLE_LIST = [
  { id: 1, name: 'STAFF', description: 'staff role', privileges: 1 },
  { id: 2, name: 'ADMIN', description: 'admin role', privileges: 2 },
  { id: 3, name: 'OWNER', description: 'gym owner role', privileges: 3 },
  { id: 4, name: 'FRONTDESK', description: 'basic operations', privileges: 4 },
];

export const defaultStaffRoles = {
  [STAFF_ROLE_IDS.ADMIN]: {
    permissions: [
      { UID: SECTIONS.CALENDAR },
      { UID: SECTIONS.CRM },
      { UID: SECTIONS.STORE },
      { UID: SECTIONS.ADMIN },
      { UID: SECTIONS.STORE_ADMIN },
      { UID: SECTIONS.CALENDAR_ADMIN },
      { UID: SECTIONS.SETTINGS },
      { UID: SECTIONS.SUPPORT },
      { UID: SECTIONS.CHECKOUT },
      { UID: SECTIONS.MERCHANT_TRACK, private: true },
      { UID: SECTIONS.FORMS },
      { UID: SECTIONS.MEMBERSHIP_MANAGEMENT },
      { UID: SECTIONS.POINTS },
      { UID: PERMISSIONS.ACTIVATE_DEACTIVATE_PASSES },
      { UID: PERMISSIONS.ACTIVATE_DEACTIVATE_MEMBERSHIPS },
      { UID: PERMISSIONS.ACTIVATE_DEACTIVATE_PRODUCT },
      { UID: PERMISSIONS.APPLY_DISCOUNTS },
      { UID: PERMISSIONS.CANCEL_ORDER },
      { UID: PERMISSIONS.CANCEL_MEMBERSHIPS },
      { UID: PERMISSIONS.CREATE_CUSTOMER_PROFILE },
      { UID: PERMISSIONS.EDIT_DISCOUNT_AND_END_DATE },
      { UID: PERMISSIONS.EDIT_MEMBERSHIP },
      { UID: PERMISSIONS.OPEN_EDIT_DRAWER },
      { UID: PERMISSIONS.REFUND_ORDER },
      { UID: PERMISSIONS.RECORD_ADJUST_INVENTORY },
      { UID: PERMISSIONS.UPDATE_CUSTOMER_PROFILE },
      { UID: PERMISSIONS.VOID_CHECKIN },
      { UID: SUB_SECTIONS.STAFF },
      { UID: SUB_SECTIONS.STAFF_ROLES },
      { UID: SUB_SECTIONS.GIFTCARDS },
      { UID: SUB_SECTIONS.CERTIFICATIONS },
      { UID: SUB_SECTIONS.LOCATIONS },
      { UID: SUB_SECTIONS.DOCUMENT_TEMPLATES },
      { UID: SUB_SECTIONS.PASS_TYPES },
      { UID: SUB_SECTIONS.MEMBERSHIP_TYPES },
      { UID: SUB_SECTIONS.GIFT_CARD_TYPES },
      { UID: SUB_SECTIONS.PRODUCTS },
      { UID: SUB_SECTIONS.VARIANT_TYPES },
      { UID: SUB_SECTIONS.INVENTORY_LOGS },
      { UID: SUB_SECTIONS.PROMO_CODES },
      { UID: SUB_SECTIONS.REVENUE_CATEOGORIES },
      { UID: SUB_SECTIONS.GIFTCARDS },
      { UID: SUB_SECTIONS.CASH_DRAWER },
      { UID: SUB_SECTIONS.MERGE_ACCOUNTS },
      { UID: PERMISSIONS.ADJUST_STORE_CREDIT },
      { UID: PERMISSIONS.CONVERT_GIFT_CARDS },
      { UID: PERMISSIONS.CREATE_AD_HOC_CHARGE },
      { UID: PERMISSIONS.CREATE_EVENT },
      { UID: PERMISSIONS.ADJUST_POINTS },
      { UID: PERMISSIONS.REDEEM_REWARDS },
      { UID: PERMISSIONS.VIEW_MEMBERSHIP_HISTORY },
    ],
  },
  [STAFF_ROLE_IDS.STAFF]: {
    permissions: [
      { UID: SECTIONS.CALENDAR },
      { UID: SECTIONS.CRM },
      { UID: SECTIONS.STORE },
      { UID: SECTIONS.CHECKOUT },
      { UID: SECTIONS.FORMS },
      { UID: SECTIONS.MEMBERSHIP_MANAGEMENT },
      { UID: SECTIONS.POINTS },
      { UID: PERMISSIONS.APPLY_DISCOUNTS },
      { UID: PERMISSIONS.CANCEL_ORDER },
      { UID: PERMISSIONS.CANCEL_MEMBERSHIPS },
      { UID: PERMISSIONS.CREATE_CUSTOMER_PROFILE },
      { UID: PERMISSIONS.EDIT_DISCOUNT_AND_END_DATE },
      { UID: PERMISSIONS.EDIT_MEMBERSHIP },
      { UID: PERMISSIONS.OPEN_EDIT_DRAWER },
      { UID: PERMISSIONS.REFUND_ORDER },
      { UID: PERMISSIONS.UPDATE_CUSTOMER_PROFILE },
      { UID: PERMISSIONS.VOID_CHECKIN },
      { UID: SUB_SECTIONS.GIFTCARDS },
      { UID: SUB_SECTIONS.CASH_DRAWER },
      { UID: SUB_SECTIONS.MERGE_ACCOUNTS },
    ],
  },
  [STAFF_ROLE_IDS.OWNER]: {
    permissions: [
      { UID: SECTIONS.CALENDAR },
      { UID: SECTIONS.CRM },
      { UID: SECTIONS.STORE },
      { UID: SECTIONS.FINANCES },
      { UID: SECTIONS.ADMIN },
      { UID: SECTIONS.STORE_ADMIN },
      { UID: SECTIONS.CALENDAR_ADMIN },
      { UID: SECTIONS.SETTINGS },
      { UID: SECTIONS.SUPPORT },
      { UID: SECTIONS.CHECKOUT },
      { UID: SECTIONS.MERCHANT_TRACK, private: true },
      { UID: SECTIONS.FORMS },
      { UID: SECTIONS.MEMBERSHIP_MANAGEMENT },
      { UID: SECTIONS.POINTS },
      { UID: PERMISSIONS.ACTIVATE_DEACTIVATE_PASSES },
      { UID: PERMISSIONS.ACTIVATE_DEACTIVATE_MEMBERSHIPS },
      { UID: PERMISSIONS.ACTIVATE_DEACTIVATE_PRODUCT },
      { UID: PERMISSIONS.APPLY_DISCOUNTS },
      { UID: PERMISSIONS.CANCEL_ORDER },
      { UID: PERMISSIONS.CANCEL_MEMBERSHIPS },
      { UID: PERMISSIONS.CREATE_CUSTOMER_PROFILE },
      { UID: PERMISSIONS.EDIT_DISCOUNT_AND_END_DATE },
      { UID: PERMISSIONS.EDIT_MEMBERSHIP },
      { UID: PERMISSIONS.OPEN_EDIT_DRAWER },
      { UID: PERMISSIONS.REFUND_ORDER },
      { UID: PERMISSIONS.RECORD_ADJUST_INVENTORY },
      { UID: PERMISSIONS.UPDATE_CUSTOMER_PROFILE },
      { UID: PERMISSIONS.VOID_CHECKIN },
      { UID: SUB_SECTIONS.STAFF },
      { UID: SUB_SECTIONS.STAFF_ROLES },
      { UID: SUB_SECTIONS.GIFTCARDS },
      { UID: SUB_SECTIONS.CERTIFICATIONS },
      { UID: SUB_SECTIONS.LOCATIONS },
      { UID: SUB_SECTIONS.DOCUMENT_TEMPLATES },
      { UID: SUB_SECTIONS.PASS_TYPES },
      { UID: SUB_SECTIONS.MEMBERSHIP_TYPES },
      { UID: SUB_SECTIONS.GIFT_CARD_TYPES },
      { UID: SUB_SECTIONS.PRODUCTS },
      { UID: SUB_SECTIONS.VARIANT_TYPES },
      { UID: SUB_SECTIONS.INVENTORY_LOGS },
      { UID: SUB_SECTIONS.PROMO_CODES },
      { UID: SUB_SECTIONS.REVENUE_CATEOGORIES },
      { UID: SUB_SECTIONS.GIFTCARDS },
      { UID: SUB_SECTIONS.CASH_DRAWER },
      { UID: SUB_SECTIONS.MERGE_ACCOUNTS },
      { UID: PERMISSIONS.ADJUST_STORE_CREDIT },
      { UID: PERMISSIONS.CONVERT_GIFT_CARDS },
      { UID: PERMISSIONS.CREATE_AD_HOC_CHARGE },
      { UID: PERMISSIONS.CREATE_EVENT },
      { UID: PERMISSIONS.ADJUST_POINTS },
      { UID: PERMISSIONS.REDEEM_REWARDS },
      { UID: PERMISSIONS.VIEW_MEMBERSHIP_HISTORY },
    ],
  },
};
