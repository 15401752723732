export const isAutomaticLogoutActive = (state) => (
  state.settings.generalSettings.automaticLogout?.isActive ?? false
);
export const automaticLogoutTimeout = (state) => (
  state.settings.generalSettings.automaticLogout?.minutes ?? 15
);
export const areSettingsLoaded = (state) => (
  state.settings.settingsLoaded
);
export const playCheckinSounds = (state) => (
  state.settings.checkinSettings.playCheckinSounds ?? false
);
export const areRevenueCategoriesRequired = (state) => (
  state.settings.generalSettings.requireRevenueCategories ?? false
);
export const checkoutOrderOnlyAtLocationOfPurchase = (state) => (
  state.settings.generalSettings.requireCheckOutAtLocationOfPurchase ?? false
);
export const isStoreCreditActive = (state) => (
  state.settings.generalSettings.storeCredit?.isActive ?? false
);
export const isAdHocChargeActive = (state) => (
  state.settings.generalSettings.adhocCharge ?? false
);
export const isPointsActive = (state) => (
  state.settings.generalSettings.points?.isActive ?? false
);
export const defaultPlansView = (state) => (
  state.settings.defaultSettings?.plansView ?? 'CURRENT'
);
