import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';


const CssSpinner = (props) => (
  <div
    className={classnames('spinner-border', {
      'spinner-border-sm': props.size === 'sm',
      'spinner-border-lg': props.size === 'lg',
      'spinner-border-xl': props.size === 'xl',
    })}
  />
);

CssSpinner.propTypes = {
  size: PropTypes.string,
};

CssSpinner.defaultProps = {
  size: 'md',
};


export default CssSpinner;
