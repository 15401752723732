import React from 'react';
import { PropTypes } from 'prop-types';
import {
  ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction,
  IconButton, Checkbox, FormControlLabel,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import CalendarListItemHamburgerMenu from '../../CalendarListItemHamburgerMenu';
import { HamburgerButton } from '../../hamburger';


class CalendarSearchListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarColorPickerAnchorEl: null,
      showHamburgerMenu: false,
    };
  }

  handleCalendarMoreClick = (event) => {
    event.stopPropagation();

    this.setState({
      calendarColorPickerAnchorEl: event.currentTarget,
      showHamburgerMenu: true,
    });
  };

  handleColorSelect = (color) => {
    this.hideCalendarColorPicker();
    this.props.onColorChange(color);
  };

  hideCalendarColorPicker = () => this.setState({
    calendarColorPickerAnchorEl: null,
    showHamburgerMenu: false,
  });

  handleHamburgerButtonClick = (event) => {
    this.handleCalendarMoreClick(event, this.props.data);
  };

  render() {
    const { data: calendar, color, onClick, onRemove, onColorChange, isSelected } = this.props;
    const { calendarColorPickerAnchorEl, showHamburgerMenu } = this.state;

    return (
      <>
        <ListItem
          button
          dense
          disableGutters
          classes={{
            root: 'calendar-search-list-item',
            container: 'calendar-search-list-item-container',
          }}
          onClick={onClick}
        >
          <ListItemIcon classes={{ root: 'list-item-icon-dense' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSelected}
                  tabIndex={-1}
                  disableRipple
                  classes={{ root: 'p-0' }}
                  style={{ color }}
                />
              }
              label={calendar.name}
              classes={{ label: 'sr-only' }}
            />
          </ListItemIcon>
          <ListItemText
            primary={calendar.name}
            primaryTypographyProps={{
              component: 'p',
              title: calendar.name,
            }}
            classes={{
              root: 'calendar-search-list-item-label',
              primary: 'calendar-search-list-item-text',
            }}
          />
          <ListItemSecondaryAction>
            {onRemove && (
              <IconButton
                size="small"
                onClick={onRemove}
                title="Remove from list"
              >
                <ClearIcon />
              </IconButton>
            )}
            {(onRemove || onColorChange) && (
              <HamburgerButton
                isActive={showHamburgerMenu}
                size="small"
                edge="end"
                onClick={this.handleHamburgerButtonClick}
              />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        {showHamburgerMenu && (
          <CalendarListItemHamburgerMenu
            open
            anchorEl={calendarColorPickerAnchorEl}
            onClose={this.hideCalendarColorPicker}
            onSelect={this.handleColorSelect}
            onRemove={onRemove}
            selectedColor={calendar.color}
          />
        )}
      </>
    );
  }
}

CalendarSearchListItem.propTypes = {
  color: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onColorChange: PropTypes.func,
  onRemove: PropTypes.func,
};


export default CalendarSearchListItem;
