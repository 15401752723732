export const TICKET_STATUS_MAP = {
  active: 'Active',
  depleted: 'Depleted',
  hold: 'On Hold',
  'cancelled-void': 'Cancelled',
};

export const TICKET_STATUS_PICKLIST_VALUES = Object.keys(TICKET_STATUS_MAP).map((value) => ({
  name: TICKET_STATUS_MAP[value],
  value,
}));
