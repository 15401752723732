export const BILLING_CONFIG = [
  { label: 'Monthly', value: 'MONTHLY' },
  { label: 'Weekly', value: 'WEEKLY' },
  { label: 'Date of Purchase', value: 'DOP' },
];

export const BILLING_DAYS = [...Array(29).keys()].slice(1).map((num) => ({ value: num, label: num }));

export const MEMBERSHIP_STATUSES = [
  'active',
  'unpaid',
  'hold',
  'cancelled',
  'cancelled-void',
  'expired',
];

export const BILLING_FIELDS = [
  'billingType',
  'billingDayOfWeek',
  'billingDayOfMonth',
  'durationType',
  'duration',
];

export const BILLING_DAYS_OF_WEEK = [
  { label: 'Sunday', value: 0 },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
];

export const DURATION_TYPES = [
  { label: 'Weeks', value: 'WEEK' },
  { label: 'Months', value: 'MONTH' },
  { label: 'Years', value: 'YEAR' },
];

export const PLAN_STATUSES = [
  { label: 'Current', value: 'CURRENT' },
  { label: 'All', value: 'ALL' },
  { label: 'Expired', value: 'EXPIRED' },
];

export const PLAN_STATUSES_MAP = PLAN_STATUSES.reduce((a, b) => {
  a[b.value] = b;
  return a;
}, {});

export const MEMBERSHIP_MODIFICATIONS = {
  Held: 'Held',
  Reactivated: 'Reactivated',
  Cancelled: 'Cancelled',
  Expired: 'Expired',
  StatusChange: 'Status Change',
  CancelledViaOrder: 'Cancelled Via Order',
  CancellationRequest: 'Cancellation Request',
  HoldDateAdded: 'Hold Date Added',
  HoldDateUpdated: 'Hold Date Updated',
  HoldDateRemoved: 'Hold Date Removed',
  CancelDateAdded: 'Cancel Date Added',
  CancelDateUpdated: 'Cancel Date Updated',
  CancelDateRemoved: 'Cancel Date Removed',
  DiscountAdded: 'Discount Added',
  DiscountUpdated: 'Discount Updated',
  DiscountRemoved: 'Discount Removed',
  DiscountDescriptionAdded: 'Discount Description Added',
  DiscountDescriptionUpdated: 'Discount Description Updated',
  DiscountDescriptionRemoved: 'Discount Description Removed',
  Reassigned: 'Reassigned',
  BillingLocationChanged: 'Billing Location Changed',
  BillingAutobillingFailed: 'Auto-billing Failed',
  StartDateAdded: 'Start Date Added',
  StartDateUpdated: 'Start Date Updated',
  StartDateRemoved: 'Start Date Removed',
  EndDateAdded: 'End Date Added',
  EndDateUpdated: 'End Date Updated',
  EndDateRemoved: 'End Date Removed',
  GuestPassQuantityUpdated: 'Guest Pass Quantity Updated',
  PurchaserUpdated: 'Purchaser Updated',
  FutureHoldDateAdded: 'Future Hold Date Added',
  FutureHoldDateUpdated: 'Future Hold Date Updated',
  FutureHoldDateRemoved: 'Future Hold Date Removed',
};
