import React from 'react';
import { PropTypes } from 'prop-types';

import { notAfter } from 'service/utility/errorMessages';

import DatetimeField from '../DatetimeField';


class DatetimeRange extends React.Component {
  constructor(props) {
    super(props);

    this.componentError = null;
  }

  handleChange = (varName) => (update) => {
    const {
      onChange, value, error, startLabel, endLabel, fieldName,
      startField: { name: startName }, endField: { name: endName },
    } = this.props;

    if (!onChange) return;

    const hasNewValue = update.hasOwnProperty('value');
    const hasNewError = update.hasOwnProperty('error');

    if (!hasNewValue && !hasNewError) return;

    const newValue = update.value;
    const newError = update.error;
    const oldValue = value[varName];
    const oldError = error[varName];
    const valueHasChanged = oldValue !== newValue;
    const errorHasChanged = oldError !== newError;

    if (
      (!hasNewValue || (hasNewValue && !valueHasChanged)) &&
      (!hasNewError || (hasNewError && !errorHasChanged))
    ) {
      return;
    }

    const compositeUpdate = {};
    const newValues = {
      [startName]: this.props.value[startName],
      [endName]: this.props.value[endName],
    };

    if (hasNewValue && valueHasChanged) {
      newValues[varName] = newValue;
      compositeUpdate.values = {
        [varName]: newValue,
      };
    }

    if (hasNewError && errorHasChanged) {
      compositeUpdate.errors = {
        [varName]: newError,
      };
    }

    const newStartValue = newValues[startName];
    const newEndValue = newValues[endName];
    const newComponentError = (
      Boolean(newStartValue) && Boolean(newEndValue) && newStartValue > newEndValue
        ? notAfter(startLabel, endLabel)
        : null
    );

    if (newComponentError !== this.componentError) {
      this.componentError = newComponentError;
      compositeUpdate.errors = {
        ...(compositeUpdate.errors || {}),
        [fieldName]: newComponentError,
      };
    }

    onChange(compositeUpdate);
  };

  handleStartDTChange = this.handleChange(this.props.startField.name);

  handleEndDTChange = this.handleChange(this.props.endField.name);


  render() {
    const {
      timeZone, value, error, startField, endField, startLabel, endLabel,
    } = this.props;

    return (
      <>
        <div className="field-wrapper">
          <DatetimeField
            fullWidth
            required={startField.isRequired}
            label={startLabel}
            value={value[startField.name]}
            error={error[startField.name] || this.componentError}
            onChange={this.handleStartDTChange}
            timeZone={timeZone}
          />
        </div>
        <div className="field-wrapper">
          <DatetimeField
            fullWidth
            required={endField.isRequired}
            label={endLabel}
            value={value[endField.name]}
            error={error[endField.name]}
            onChange={this.handleEndDTChange}
            timeZone={timeZone}
          />
        </div>
      </>
    );
  }
}

DatetimeRange.propTypes = {
  endField: PropTypes.object.isRequired,
  endLabel: PropTypes.string,
  error: PropTypes.object,
  fieldName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  startField: PropTypes.object.isRequired,
  startLabel: PropTypes.string,
  timeZone: PropTypes.string.isRequired,
  value: PropTypes.object,
};

DatetimeRange.defaultProps = {
  endLabel: 'End Date',
  startLabel: 'Start Date',
};


export default DatetimeRange;
