
// eslint-disable-next-line import/prefer-default-export
export const CHECKIN_STATUSES = {
  APPROVED: 'approved',
  FAILED: 'failed',
  VOID: 'void',
};

export const CHECKIN_STATUS_PICKLIST = [
  { label: 'Approved', value: CHECKIN_STATUSES.APPROVED },
  { label: 'Failed', value: CHECKIN_STATUSES.FAILED },
  { label: 'Voided', value: CHECKIN_STATUSES.VOID },
];
