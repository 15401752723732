import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import EventNoteIcon from '@material-ui/icons/EventNote';
import HeadsetMicRoundedIcon from '@material-ui/icons/HeadsetMicRounded';
import HomeIcon from '@material-ui/icons/Home';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StarIcon from '@material-ui/icons/Star';
import WorkIcon from '@material-ui/icons/Work';

import { getRoutePath } from 'service/navigation';


// eslint-disable-next-line import/prefer-default-export
export const getNavConfig = () => ([
  {
    label: 'Dashboard',
    icon: HomeIcon,
    linkTo: getRoutePath('HOME'),
  },
  {
    label: 'Calendar',
    icon: CalendarTodayIcon,
    subNav: [
      {
        label: 'Calendar',
        linkTo: getRoutePath('CALENDAR'),
      },
      {
        label: 'Event List',
        linkTo: getRoutePath('EVENTS'),
      },
      {
        label: 'Bookings',
        linkTo: getRoutePath('BOOKINGS'),
      },
    ],
  },
  {
    label: 'CRM',
    icon: PeopleIcon,
    subNav: [
      {
        label: 'Customers',
        linkTo: getRoutePath('CUSTOMERS'),
      },
      {
        label: 'Checkins',
        linkTo: getRoutePath('CHECKINS'),
      },
      {
        label: 'Groups',
        linkTo: getRoutePath('GROUPS'),
      },
      {
        label: 'Merge Accounts',
        linkTo: getRoutePath('MERGE_ACCOUNTS'),
      },
    ],
  },
  {
    label: 'Store',
    icon: ShoppingCartIcon,
    subNav: [
      {
        label: 'Shop',
        linkTo: getRoutePath('SHOP'),
      },
      {
        label: 'Orders',
        linkTo: getRoutePath('ORDERS'),
      },
      {
        label: 'Gift Cards',
        linkTo: getRoutePath('GIFT_CARDS'),
      },
      {
        label: 'Cash Drawer',
        linkTo: getRoutePath('CASH_DRAWER'),
      },
    ],
  },
  {
    label: 'Membership Management',
    icon: CardMembershipIcon,
    subNav: [
      // {
      //   label: 'Types',
      //   linkTo: getRoutePath('MEMBERSHIP_TYPES'),
      // },
      {
        label: 'List',
        linkTo: getRoutePath('MEMBERSHIP_MANAGEMENT'),
      },
      {
        label: 'Resume',
        linkTo: getRoutePath('MEMBERSHIP_RESUME_LIST'),
      },
      {
        label: 'History',
        linkTo: getRoutePath('MEMBERSHIP_HISTORY'),
      },
    ],
  },
  {
    label: 'Finances',
    icon: MonetizationOnIcon,
    subNav: [
      {
        label: 'Transactions',
        linkTo: getRoutePath('TRANSACTIONS'),
      },
      {
        label: 'ACH Transactions',
        linkTo: getRoutePath('ACH_TRANSACTIONS'),
      },
      // {
      //   label: 'Memberships',
      //   linkTo: getRoutePath('MEMBERSHIPS'),
      // },
      {
        label: 'Revenue Category Reports',
        linkTo: getRoutePath('REVENUE_CATEGORY_REPORTS'),
      },
      {
        label: 'Quickbooks Connections',
        linkTo: getRoutePath('QUICKBOOKS_CONNECTIONS'),
      },
    ],
  },
  {
    label: 'Loyalty Points',
    icon: StarIcon,
    subNav: [
      {
        label: 'Staff Adjustments',
        linkTo: getRoutePath('POINTS_STAFF_ADJUSTMENTS'),
      },
      {
        label: 'Redemptions',
        linkTo: getRoutePath('POINTS_REDEMPTIONS'),
      },
    ],
  },
  {
    label: 'Support',
    icon: HeadsetMicRoundedIcon,
    subNav: [
      {
        label: 'Requests',
        linkTo: getRoutePath('SUPPORT_REQUESTS'),
      },
      {
        label: 'History',
        linkTo: getRoutePath('SUPPORT_HISTORY'),
      },
      {
        label: 'Issue Types',
        linkTo: getRoutePath('SUPPORT_ISSUE_TYPES'),
      },
    ],
  },
  {
    label: 'Forms',
    icon: DescriptionOutlinedIcon,
    subNav: [
      {
        label: 'Questions',
        linkTo: getRoutePath('QUESTIONS'),
      },
      {
        label: 'Templates',
        linkTo: getRoutePath('TEMPLATES'),
      },
      {
        label: 'Submissions',
        linkTo: getRoutePath('SUBMISSIONS'),
      },
    ],
  },
  {
    isDivider: true,
  },
  {
    label: 'Admin',
    icon: WorkIcon,
    subNav: [
      {
        label: 'Staff',
        linkTo: getRoutePath('STAFF'),
      },
      {
        label: 'Staff Roles',
        linkTo: getRoutePath('STAFF_ROLES'),
      },
      {
        label: 'Certifications',
        linkTo: getRoutePath('CERTIFICATIONS'),
      },
      {
        label: 'Locations',
        linkTo: getRoutePath('LOCATIONS'),
      },
      {
        label: 'Document Templates',
        linkTo: getRoutePath('DOCUMENT_TEMPLATES'),
      },
    ],
  },
  {
    label: 'Store Admin',
    icon: AssignmentIcon,
    subNav: [
      {
        label: 'Pass Types',
        linkTo: getRoutePath('PASS_TYPES'),
      },
      {
        label: 'Membership Types',
        linkTo: getRoutePath('MEMBERSHIP_TYPES'),
      },
      {
        label: 'Gift Card Types',
        linkTo: getRoutePath('GIFT_CARD_TYPES'),
      },
      {
        label: 'Products',
        linkTo: getRoutePath('PRODUCTS'),
      },
      {
        label: 'Variant Types',
        linkTo: getRoutePath('VARIANT_TYPES'),
      },
      {
        label: 'Inventory Logs',
        linkTo: getRoutePath('INVENTORY_LOGS'),
      },
      {
        label: 'Promo Codes',
        linkTo: getRoutePath('PROMO_CODES'),
      },
      {
        label: 'Revenue Categories',
        linkTo: getRoutePath('REVENUE_CATEGORIES'),
      },
    ],
  },
  {
    label: 'Calendar Admin',
    icon: EventNoteIcon,
    subNav: [
      {
        label: 'Calendars',
        linkTo: getRoutePath('CALENDARS'),
      },
      {
        label: 'Pricing Tiers',
        linkTo: getRoutePath('PRICING_TIERS'),
      },
      {
        label: 'Ticket Types',
        linkTo: getRoutePath('TICKET_TYPES'),
      },
      {
        label: 'Categories',
        linkTo: getRoutePath('CATEGORIES'),
      },
      {
        label: 'Merge Calendars',
        linkTo: getRoutePath('CALENDAR_MERGE'),
      },
    ],
  },
  {
    label: 'Settings',
    icon: SettingsIcon,
    linkTo: getRoutePath('SETTINGS'),
  },
  {
    label: 'MerchantTrack',
    icon: AccountBalanceIcon,
    linkTo: getRoutePath('MERCHANT_TRACK'),
    //locationFilter: isFullSteamLocation,
  },
]);
