import React from 'react';
import { PropTypes } from 'prop-types';

import Avatar from './Avatar';


const EventAvatar = (props) => (
  <Avatar
    className="event-avatar"
    {...props}
  />
);

EventAvatar.propTypes = {
  onClick: PropTypes.func,
  onImageUpdate: PropTypes.func,
  size: PropTypes.string,
  src: PropTypes.string,
};

EventAvatar.defaultProps = {
  size: 'md',
  src: '',
};


export default EventAvatar;
